import React, { useEffect, useState } from "react";
import "./Creator.css";
import Slider from "react-slick";
import { images } from "../../constants";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import { useScroll } from "../../ScrollContext";

const items = [
  {
    id: 1,
    name: "Protect",
    content:
      "Our platform uses advanced blockchain technology to ensure that each creation is securely stored and protected from duplication or tampering. Your brilliance is preserved for generations to come",
    img: images.Profit,
  },
  {
    id: 2,
    name: "Promote",
    content:
      "rarerez.com provides a stage for you to tell the story behind your creations, connecting you with a community of passionate enthusiasts who appreciate your talent and artistry.",
    img: images.Profit,
  },
  {
    id: 3,
    name: "Profit",
    content:
      " As an rarerez® creator, you participate in a groundbreaking royalty structure, earning ongoing rewards with each subsequent sale of your digital assets. Monetize your creations like never before.      ",
    img: images.Profit,
  },
  {
    id: 4,
    name: "Protect",
    content:
      "Our platform uses advanced blockchain technology to ensure that each creation is securely stored and protected from duplication or tampering. Your brilliance is preserved for generations to come",
    img: images.Profit,
  },
  {
    id: 5,
    name: "Promote",
    content:
      "rarerez.com provides a stage for you to tell the story behind your creations, connecting you with a community of passionate enthusiasts who appreciate your talent and artistry.",
    img: images.Profit,
  },
  {
    id: 6,
    name: "Profit",
    content:
      " As an rarerez® creator, you participate in a groundbreaking royalty structure, earning ongoing rewards with each subsequent sale of your digital assets. Monetize your creations like never before.      ",
    img: images.Profit,
  },

  // Add more items as needed
];

const Creator = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { setScrollTo } = useScroll();
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button className={`custom-arrow2 custom-prev-arrow2`} onClick={onClick}>
        <img
          src={images.left_arrow}
          style={{
            width: "30px",
            height: "30px",
            objectFit: "contain",
            zIndex: 11,
          }}
          alt="Previous"
        />
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    return (
      <div className={`custom-arrow2 custom-next-arrow2`} onClick={onClick}>
        <img
          src={images.right_arrow}
          style={{ width: "30px", height: "30px", objectFit: "contain" }}
          alt="Next"
        />
      </div>
    );
  };
  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleExitAnimation = () => {
    setIsVisible(false);
  };
  const [centerSlide, setCenterSlide] = useState(0);
  const settings = {
    centerMode: true,
    centerPadding: "4%",
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          // slidesToShow: 1,
          // slidesToScroll: 1,
          // initialSlide: 1,
          arrows: true,
        },
      },
    ],
  };

  const appear = {
    from: 0,
    to: 1,
    attributeName: "opacity",
  };

  const leave = {
    steps: [
      {
        style: {
          transform: "translateX(0)",
        },
      },
      {
        duration: 500,
        style: {
          transform: "translateX(300)",
          height: 50,
        },
      },
      {
        duration: 1000,
        style: {
          height: 0,
        },
      },
    ],
  };

  return (
    <div className="creator_main_wrapp">
      <div className="creator_img_wrapp"></div>
      <Link
        to="/"
        onClick={() => {
          setScrollTo(1);
        }}
      >
        <Slide right>
          <div className="creator_floting_name_wrapp">
            <BsArrowLeftShort
              style={{ color: "var(--color-white)" }}
              size={34}
            />
            <h4 className="creator_active">CREATOR</h4>
          </div>
        </Slide>
      </Link>
      <Link to={"/Collector"}>
        <Slide left>
          <div className="creator_floting_name_wrapp2">
            <h4 className="creator_floting_name">COLLECTOR</h4>
            <BsArrowRightShort
              style={{ color: "var(--color-black)" }}
              size={34}
            />
          </div>
        </Slide>
      </Link>
      <Slide right>
        <div className="creator_slider_main_wrapp">
          <Slider {...settings}>
            {items.map((item, index) => (
              <div key={item.id} className="creator_slide_item">
                <div className="creator_slide_inner">
                  <img src={item.img} alt="" className="creator_slide_img" />
                  <h2
                    style={{
                      fontFamily: "var(--font-600)",
                      color: "white",
                      fontSize: "30px",
                      width: "90%",
                    }}
                  >
                    {item.name}
                  </h2>
                  <div className="creator_slide_description">
                    <p>{item.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Slide>
    </div>
  );
};

export default Creator;
