import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { images } from "../../constants";
import "./blog.css";

const Data = [
  {
    id: 1,
    img: images.blog1,
    heading: "Art-1",
    text2: "This Week in Web3 and NFTs",
    date: "july 26,2023",
    description:
      "Welcome to the OpenSea digest.Let's look backn through the bigest NFT and Web3 news of the week.",
  },
  {
    id: 2,
    img: images.blog2,
    heading: "Entertainment-1",
    text2: "This Week in Web3 and NFTs",
    date: "july 26,2023",
    description:
      "Welcome to the OpenSea digest.Let's look backn through the bigest NFT and Web3 news of the week.",
  },
  {
    id: 3,
    img: images.blog5,
    heading: "History",
    text2: "This Week in Web3 and NFTs",
    date: "july 26,2023",
    description:
      "Welcome to the OpenSea digest.Let's look backn through the bigest NFT and Web3 news of the week.",
  },
  {
    id: 4,
    img: images.blog4,
    heading: "Art-2",
    text2: "This Week in Web3 and NFTs",
    date: "july 26,2023",
    description:
      "Welcome to the OpenSea digest.Let's look backn through the bigest NFT and Web3 news of the week.",
  },
  {
    id: 5,
    img: images.blog3,
    heading: "Entertainment-2",
    text2: "This Week in Web3 and NFTs",
    date: "july 26,2023",
    description:
      "Welcome to the OpenSea digest.Let's look backn through the bigest NFT and Web3 news of the week.",
  },
  {
    id: 6,
    img: images.blog6,
    heading: "Culture",
    text2: "This Week in Web3 and NFTs",
    date: "july 26,2023",
    description:
      "Welcome to the OpenSea digest.Let's look backn through the bigest NFT and Web3 news of the week.",
  },
];

const Blog = () => {
  const [data, setData] = useState(Data);
  const [selectedHeading, setSelectedHeading] = useState(null);
  const navigate = useNavigate();

  // Function to filter the data based on the selected heading
  const handleFilter = (heading) => {
    if (heading === selectedHeading) {
      // If the same heading is clicked again, reset the filter and show all blogs
      setSelectedHeading(null);
      setData(Data);
    } else {
      // Otherwise, filter the data based on the clicked heading
      const filteredData = Data.filter((item) => item.heading.includes(heading));
      setData(filteredData);
      setSelectedHeading(heading);
    }
  };

  // Function to reset the filter and show all blogs
  const showAllBlogs = () => {
    setSelectedHeading(null);
    setData(Data);
  };

  // Function to handle link click and navigate to the second page with data
  const handleLinkClick = (item) => {
    navigate("/blogdetails", { state: item }); // Pass the item object as state
  };

  return (
    <div className="blog_main_wrapp">
      <div className="blog_main_wrapp_heading">
        <button className="blog_btn" onClick={showAllBlogs}>
          Show All
        </button>
        <button
          className={`blog_btn ${selectedHeading === "Art" ? "active" : ""}`}
          onClick={() => handleFilter("Art")}
        >
          Art
        </button>
        <button
          className={`blog_btn ${selectedHeading === "Entertainment" ? "active" : ""}`}
          onClick={() => handleFilter("Entertainment")}
        >
          Entertainment
        </button>
        <button
          className={`blog_btn ${selectedHeading === "History" ? "active" : ""}`}
          onClick={() => handleFilter("History")}
        >
          History
        </button>
        <button
          className={`blog_btn ${selectedHeading === "Culture" ? "active" : ""}`}
          onClick={() => handleFilter("Culture")}
        >
          Culture
        </button>
        {/* Add more buttons here for other topics */}
      </div>
      <div className="blod_card_main">
        {data && data.length > 0 ? (
          data.map((item, index) => {
            return (
              <div
                key={item.id}
                className="blog_card"
                onClick={() => handleLinkClick(item)}
              >
                <Link to={"/blogdetails"} className="blog_card">
                  <img src={item.img} className="blog_card_img" alt="" />
                  <div className="blog_details_card">
                    <p className="blog_card_heading">{item.heading}</p>
                    <p className="blog_card_text_2">{item.text2}</p>
                    <p className="blog_card_date">{item.date}</p>
                    <p className="blog_card_description">{item.description}</p>
                  </div>
                </Link>
              </div>
            );
          })
        ) : (
          <p>No data available.</p>
        )}
      </div>
    </div>
  );
};

export default Blog;
