import React from "react";
import "./refundandcancel.css";
import { images } from "../../constants";

const Refundandcancel = () => {
  return (
    <div className="privacypolicy_main_wrapp">
      <div className="termsofuse_main_wrapp_heading">
        <b className="h1_terms">Refund and Cancellation Policy</b> <br />
        <br />
        <span className="terms_date">Last Updated: 17/11/2023</span>
        <br />
        <br />
        <b className="h1_terms">Introduction:</b> <br />
        <br />
        <span>
          This Refund and Cancellation Policy outlines the terms and conditions
          governing refund requests and cancellations for products or services
          provided by NFB Tech Platform Pvt Limited. Please read this policy
          carefully before making any purchase or engaging with our services. By
          using our platform, you agree to abide by the terms outlined below.
          <br /> <br />
          <p>1. Nature of Business:</p>
          <br />
          NFB Tech Platform Pvt Limited operates in a dynamic industry,
          providing innovative and technologically driven solutions. Due to the
          nature of our business, we regret to inform our users that we do not
          offer any refunds or cancellations once a purchase has been made or a
          service has been engaged.
          <br />
          <br />
          <p>2. No Refunds:</p>
          <br />
          As a general rule, all sales are final. We do not entertain requests
          for refunds under any circumstances. This policy is in place to
          account for the instantaneous and often irreversible nature of the
          digital products or services provided by NFB Tech Platform Pvt
          Limited.
          <br />
          <br />
          <p>3. No Cancellations:</p>
          <br />
          Once an order or service has been confirmed and payment has been
          processed, cancellations are not allowed. This includes but is not
          limited to software licenses, subscription services, or any other
          digital products.
          <br />
          <br />
          <p>4. Exceptional Circumstances:</p>
          <br />
          In rare cases, NFB Tech Platform Pvt Limited may, at its discretion,
          consider refund or cancellation requests in exceptional circumstances
          such as technical errors resulting in duplicate purchases, billing
          errors, or other situations deemed appropriate by our management.
          <br />
          <br />
          <p>5. Contacting Support:</p>
          <br />
          If you believe you have a valid reason for a refund or cancellation
          under exceptional circumstances, you may contact our customer support
          team within 48 hours of the purchase or service activation. Our team
          will review the request and respond accordingly. Please provide
          detailed information about the issue, along with any supporting
          documentation.
          <br />
          <br />
          <p>6. Refund Processing Time:</p>
          <br />
          If a refund is approved, please note that it may take a certain amount
          of time for the refund to be processed and reflected in your account.
          The processing time can vary based on payment methods and financial
          institutions involved.
          <br />
          <br />
          <p>7. Changes to the Policy:</p>
          <br />
          NFB Tech Platform Pvt Limited{" "}
          <a href="mailto:legal@nfb.club">legal@nfb.club</a> the right to modify
          or update this Refund and Cancellation Policy at any time without
          prior notice. Users are encouraged to review this policy periodically
          for any changes.
          <br />
          <br />
          <p>8. Acceptance of Terms:</p>
          <br />
          By making a purchase or using our services, you acknowledge that you
          have read, understood, and agreed to the terms and conditions outlined
          in this Refund and Cancellation Policy.
          <br />
          <br />
          For any further clarification or inquiries, please contact our
          customer support team at{" "}
          <a href="mailto:legal@nfb.club">legal@nfb.club</a>
          <br />
          <br />
          <b>Note: </b>NFB Tech Platform Pvt Limited is a fictional name used
          for illustrative purposes. Please replace it with your actual company
          name.
          <br />
        </span>
      </div>
    </div>
  );
};

export default Refundandcancel;
