import React, { useState } from "react";
import "./UnlockPower2.css";
import { images } from "../../constants";

const UnlockPower = () => {
  const [openSection, setOpenSection] = useState("protect");

  // Function to toggle the accordion sections
  const toggleAccordion = (section) => {
    // If the clicked section is already open, close it
    if (openSection === section) {
      setOpenSection(null);
    } else {
      // Otherwise, open the clicked section
      setOpenSection(section);
    }
  };

  return (
    <div className="unlock_main_wrapp">
      <img src={images.up_bg_image2} alt="" className="unlock_power_back_img" />
      <div className="disc_uniq_inner_wrapp2">
        <p
          className="disc_uniq__text2"
          style={{ zIndex: 11, color: "var(--color-main)" }}
        >
          Unlock the Power of Your Legacy with rarerez®
        </p>
        <div className="unlock_power_cards_sec_wrapp">
          <div className="up_timeline_main">
            {/* Protect Your Legacy */}
            <div className="accordion_section">
              <div
                className="accordion_title"
                onClick={() => toggleAccordion("protect")}
              >
                <h6
                  className={
                    openSection === "protect" ? "accordion_head_font" : ""
                  }
                >
                  Protect Your Legacy
                </h6>
                <span
                  className={`accordion_icon ${
                    openSection === "protect" ? "open" : ""
                  }`}
                ></span>
              </div>
              {openSection === "protect" && (
                <div className="accordion_content">
                  <h3>Preserve Your Life's Journey</h3>
                  <p>
                    From your earliest memories to your latest achievements,
                    rarerez® offers a secure platform to chronicle your legacy.
                    Create digital milestones, document milestones, and
                    safeguard your story for generations to come.
                  </p>
                </div>
              )}
            </div>

            {/* Promote Your Assets */}
            <div className="accordion_section">
              <div
                className="accordion_title"
                onClick={() => toggleAccordion("promote")}
              >
                <h6
                  className={
                    openSection === "promote" ? "accordion_head_font" : ""
                  }
                >
                  Promote Your Assets
                </h6>
                <span
                  className={`accordion_icon ${
                    openSection === "promote" ? "open" : ""
                  }`}
                ></span>
              </div>
              {openSection === "promote" && (
                <div className="accordion_content">
                  <h3>Share Your Passion with the World</h3>
                  <p>
                    Whether it's your artwork, memorabilia, or cherished
                    possessions, rarerez® provides a global stage to showcase
                    your assets. Amplify your reach, engage with fans, and leave
                    a lasting impression on your audience.
                  </p>
                </div>
              )}
            </div>

            {/* Profit from rarerez® */}
            <div className="accordion_section">
              <div
                className="accordion_title"
                onClick={() => toggleAccordion("profit")}
              >
                <h6
                  className={
                    openSection === "profit" ? "accordion_head_font" : ""
                  }
                >
                  Profit from rarerez®
                </h6>
                <span
                  className={`accordion_icon ${
                    openSection === "profit" ? "open" : ""
                  }`}
                ></span>
              </div>
              {openSection === "profit" && (
                <div className="accordion_content">
                  <h3>Monetize Your Creativity</h3>
                  <p>
                    Transform your assets into digital collectibles (rarerez®)
                    and tap into a new revenue stream. Earn royalties from every
                    rarerez® transaction, turning your passion into profit
                    effortlessly.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnlockPower;
