import React, { useState, useEffect } from "react";
import axios from "axios";
import "./contactus.css";
import ReCAPTCHA from "react-google-recaptcha";
import { images } from "../../constants";

const Contactus = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone_number: "",
    message: "",
  });

  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [errors, setErrors] = useState({});
  const [textBackgroundColor, setTextBackgroundColor] = useState("");

  useEffect(() => {
    generateCaptcha();
  }, []);

  const generateCaptcha = () => {
    const alphabets = "abcdefghijklmnopqrstuvwxyz";
    let randomCaptcha = "";
    for (let i = 0; i < 4; i++) {
      randomCaptcha += alphabets[Math.floor(Math.random() * alphabets.length)];
    }
    setCaptchaCode(randomCaptcha);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    const validationErrors = {};
    if (!value.trim()) {
      validationErrors[name] = `${name} is required`;
    } else if (name === "email" && !isValidEmail(value)) {
      validationErrors.email = "Invalid email format";
    } else if (name === "phone_number" && !isValidPhoneNumber(value)) {
      validationErrors.phone_number = "Invalid phone number format";
    }
    setErrors(validationErrors);
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value.slice(0, 4));
    setTextBackgroundColor(value);
  };
  const [isSuccess, setIsSuccess] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = {};
    if (!formData.name.trim()) {
      validationErrors.name = "Name is required";
    }
    if (!formData.email.trim()) {
      validationErrors.email = "Email is required";
    } else if (!isValidEmail(formData.email)) {
      validationErrors.email = "Invalid email format";
    }
    if (!formData.phone_number.trim()) {
      validationErrors.phone_number = "Phone Number is required";
    } else if (!isValidPhoneNumber(formData.phone_number)) {
      validationErrors.phone_number = "Invalid phone number format";
    }
    if (!captchaValue.trim()) {
      validationErrors.captcha = "Please enter the captcha";
    } else if (!isCaptchaValid()) {
      validationErrors.captcha = "Invalid captcha";
    }
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      try {
        const response = await axios.post(
          "https://gokulnair.com/nfb_web/vadmin/api/add_contactus",
          formData
        );
        // console.log("Form submitted:", response.data);
        setIsSuccess(true);
        setIsModalOpen(true);
        setFormData({
          name: "",
          email: "",
          phone_number: "",
          message: "",
        });
        setCaptchaValue("");
        generateCaptcha();
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const isValidEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const isValidPhoneNumber = (phone_number) => {
    const phonePattern = /^\d{10,12}$/;
    return phonePattern.test(phone_number);
  };

  const isCaptchaValid = () => {
    return captchaValue === captchaCode;
  };

  const address =
    "Foundation505 Embassy Appartment, Shastri Nagar Lane 1, Near Lokhandwala Circle, Andheri West, Mumbai 400053";

  const openInGoogleMaps = () => {
    const mapsUrl = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
      address
    )}`;
    window.open(mapsUrl, "_blank");
  };

  return (
    <div
      className="contactus_main_wrapp"
      style={{ backgroundColor: textBackgroundColor }}
    >
      <div className="contactus_main_wrapp_heading">
        <div className="contacts_details">
          <b className="h1_terms">LET'S CONNECT</b> <br />
          <br />
          <label className="terms_date">Write us</label>
          <form onSubmit={handleSubmit} method="POST">
            <div>
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ margin: "0px" }}
              />
              {errors.name && <div className="error">{errors.name}</div>}
            </div>
            <div>
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.email && <div className="error">{errors.email}</div>}
            </div>
            <div>
              <label htmlFor="phone_number">Phone Number</label>
              <input
                type="number"
                name="phone_number"
                value={formData.phone_number}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              {errors.phone_number && (
                <div className="error">{errors.phone_number}</div>
              )}
            </div>
            <div>
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                cols="30"
                rows="5"
              />
            </div>
            <div className="captcha-container">
              <label htmlFor="captcha">Enter Captcha</label>
              <div className="new_add_number">
                <input
                  type="text"
                  id="captchaInput"
                  value={captchaValue}
                  onChange={(e) => handleCaptchaChange(e.target.value)}
                  maxLength={4}
                />
                <div className="captcha">
                  <span className="captcha-number">{captchaCode}</span>
                </div>
                {errors.captcha && (
                  <div className="error">{errors.captcha}</div>
                )}
                <div className="errormsg_cpatcha_code">
                  <div className="errormsg_cpatcha_code1"></div>
                  <div className="errormsg_cpatcha_code2">
                    <span
                      className="chnages_captcha_code"
                      onClick={generateCaptcha}
                    >
                      can't read the image? click here to refresh
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button className="btn_contacts" type="submit">
                Send
              </button>
            </div>
          </form>
        </div>
        <div className="contacts_details2">
          <b>Office</b>
          <p>rarerez.com</p>
          <p>505 Embassy ,</p>
          <p onClick={openInGoogleMaps}>
            Shastri Nagar Lane 1, Near Lokhandwala Circle,
          </p>
          <p>Andheri West, Mumbai 400053</p>
          <br />
          <b>Mail us</b>
          <p>
            <a href="mailto:manoj@rarerez.com">manoj@rarerez.com</a>
          </p>
          <br />
          <b>Call us</b>
          <p>
            Manoj PC : <a href="tel:+91 8691800002">+91 86918 00002</a>
          </p>
        </div>
        {isModalOpen && (
          <div className="modal">
            {isSuccess ? (
              <div className="modal-content">
                <div className="new_logo_span_new">
                  <img
                    src={images.logo_white}
                    className="new_logo_contact"
                    alt=""
                  />
                  <span className="new_logo_span">rarerez.com</span>
                </div>
                <p className="submit_contact_p">Thank you</p>
                <button
                  className="submit_contact_btn"
                  onClick={() => setIsModalOpen(false)}
                >
                  OK
                </button>
              </div>
            ) : (
              <div className="modal-content">
                <p>There was an error submitting the form.</p>
                <button className="" onClick={() => setIsModalOpen(false)}>
                  OK
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Contactus;
