import React, { useState } from "react";
import "./DiscoverUniq2.css";
import { images } from "../../constants";

const DiscoverUniq = () => {
  // Instead of using a single state object with booleans,
  // use a state variable to track the currently open section
  const [openSection, setOpenSection] = useState("protect");

  // Function to toggle the accordion sections
  const toggleAccordion = (section) => {
    // If the clicked section is already open, close it
    if (openSection === section) {
      setOpenSection(null);
    } else {
      // Otherwise, open the clicked section
      setOpenSection(section);
    }
  };

  return (
    <div className="disc_uniq_main_wrapp">
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_red_circule_right"
      />
      <img
        src={images.new_bg_circule}
        alt=""
        className="back_red_circule_left"
      />

      {/* inner section start */}

      <div className="disc_uniq_inner_wrapp">
        {/* <p className="howitwork_text1">Why Choose rarerez®</p> */}
        <p className="disc_uniq__text2" style={{ color: "var(--color-main)" }}>
          Discover the Unique Benefits of Our Platform
        </p>

        {/* <div className="disc_uniq_3card_wrapp">
          <div className="disc_uniq_sigle_card">
            <div className="disc_uniq_sigle_card_head_wrapp">
              <div></div>
              <p>Innovative Blockchain Technology:</p>
            </div>

            <div className="disc_uniq_sigle_card_backgroud_wrapp">
              <h6>Experience the Future of Asset Management</h6>
              <p>
                rarerez® leverages cutting-edge blockchain technology to ensure
                the authenticity and rarity of your digital collectibles. With
                our private blockchain system and rarity lock feature, each
                rarerez® token is securely linked to its corresponding asset,
                guaranteeing exclusivity and value.
              </p>
            </div>

            <img
              src={images.logo_gray}
              className="disc_uniq_sigle_card_flot_logo"
              alt=""
            />
          </div>
          <div className="disc_uniq_sigle_card">
            <div className="disc_uniq_sigle_card_head_wrapp">
              <div></div>
              <p>Royalty-Based Revenue Streams:</p>
            </div>

            <div className="disc_uniq_sigle_card_backgroud_wrapp">
              <h6>Maximize Your Earnings Potential</h6>
              <p>
                Unlike traditional platforms, rarerez® empowers creators and
                influencers with a royalty-based revenue model. Earn ongoing
                royalties from every rarerez® sale, providing you with a
                sustainable income stream and financial independence.
              </p>
            </div>

            <img
              src={images.logo_gray}
              className="disc_uniq_sigle_card_flot_logo"
              alt=""
            />
          </div>
          <div className="disc_uniq_sigle_card">
            <div className="disc_uniq_sigle_card_head_wrapp">
              <div></div>
              <p>Comprehensive Legacy Management:</p>
            </div>

            <div className="disc_uniq_sigle_card_backgroud_wrapp">
              <h6>Comprehensive Legacy Management:</h6>
              <p>
                From milestone creation to asset authentication, rarerez® offers
                a comprehensive suite of tools to manage your legacy with ease.
                Organize your journey, curate your assets, and unlock new
                opportunities for storytelling and self-expression.
              </p>
            </div>

            <img
              src={images.logo_gray}
              className="disc_uniq_sigle_card_flot_logo"
              alt=""
            />
          </div>
        </div> */}

        {/* Protect Your Legacy */}
        <div className="dis_uniq2_wrapp">
          <div className="up_timeline_main">
            {/* first accordian */}

            <div className="accordion_section">
              <div
                className="accordion_title"
                onClick={() => toggleAccordion("protect")}
              >
                {/* <h6>Protect Your Legacy</h6> */}
                <div className="disc_uniq_sigle_card_head_wrapp">
                  <div></div>
                  <p
                    className={
                      openSection === "protect" ? "accordion_head_font" : ""
                    }
                  >
                    Innovative Blockchain Technology:
                  </p>
                </div>
                <span
                  className={`accordion_icon ${
                    openSection === "protect" ? "open" : ""
                  }`}
                ></span>
              </div>
              {openSection === "protect" && (
                <div className="accordion_content">
                  <h3>Experience the Future of Asset Management</h3>
                  <p>
                    rarerez® leverages cutting-edge blockchain technology to
                    ensure the authenticity and rarity of your digital
                    collectibles. With our private blockchain system and rarity
                    lock feature, each rarerez® token is securely linked to its
                    corresponding asset, guaranteeing exclusivity and value.
                  </p>
                </div>
              )}
            </div>

            {/* secound accordian */}
            <div className="accordion_section">
              <div
                className="accordion_title"
                onClick={() => toggleAccordion("promote")}
              >
                {/* <h6>Protect Your Legacy</h6> */}
                <div className="disc_uniq_sigle_card_head_wrapp">
                  <div></div>
                  <p
                    className={
                      openSection === "promote" ? "accordion_head_font" : ""
                    }
                  >
                    Royalty-Based Revenue Streams:
                  </p>
                </div>
                <span
                  className={`accordion_icon ${
                    openSection === "promote" ? "open" : ""
                  }`}
                ></span>
              </div>
              {openSection === "promote" && (
                <div className="accordion_content">
                  <h3>Maximize Your Earnings Potential</h3>
                  <p>
                    Unlike traditional platforms, rarerez® empowers creators and
                    influencers with a royalty-based revenue model. Earn ongoing
                    royalties from every rarerez® sale, providing you with a
                    sustainable income stream and financial independence.
                  </p>
                </div>
              )}
            </div>

            {/* third accordian */}
            <div className="accordion_section">
              <div
                className="accordion_title"
                onClick={() => toggleAccordion("profit")}
              >
                {/* <h6>Protect Your Legacy</h6> */}
                <div className="disc_uniq_sigle_card_head_wrapp">
                  <div></div>
                  <p
                    className={
                      openSection === "profit" ? "accordion_head_font" : ""
                    }
                  >
                    Comprehensive Legacy Management:
                  </p>
                </div>
                <span
                  className={`accordion_icon ${
                    openSection === "profit" ? "open" : ""
                  }`}
                ></span>
              </div>
              {openSection === "profit" && (
                <div className="accordion_content">
                  <h3>Capture Every Moment, Preserve Every Memory</h3>
                  <p>
                    From milestone creation to asset authentication, rarerez®
                    offers a comprehensive suite of tools to manage your legacy
                    with ease. Organize your journey, curate your assets, and
                    unlock new opportunities for storytelling and
                    self-expression.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* inner section end */}
    </div>
  );
};

export default DiscoverUniq;
