import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "./HomeSlider.css"; // Create this CSS file to style the slider
import images from "../../constants/images";

const sliderImagess = [
  // images.Banner1,
  // images.Banner2,
  // images.Banner3,
  // images.Banner4,
  // images.Banner5,
  // images.Banner6,
  // images.Banner7,
  images.b_web_1,
  images.b_web_2,
  images.b_web_3,
  images.b_web_4,
  // images.b_web_5,
  // images.b_web_6,
  images.b_web_7,
];

const sliderImagesMobile = [
  images.b_1,
  images.b_2,
  images.b_3,
  images.b_4,
  // images.b_5,
  // images.b_6,
  images.b_7,
 
  // images.Baner_1,
  // images.Baner_2,
  // images.Baner_3,
  // images.Baner_4,
  // images.Baner_5,
  // images.Baner_6,
  // images.Baner_7,
];

const HomeSlider = () => {
  const [sliderImages, setSliderImages] = useState(sliderImagess);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setSliderImages(sliderImagesMobile);
      } else {
        setSliderImages(sliderImagess);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const settings = {
    dots: true,
    dotsClass: "custom-pagination-dots",
    infinite: true,
    speed: 1500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    arrows: false,
  };

  return (
    <div className="home-slider">
      <div className="slider-image-top">
        <img src={images.mobile_new} className="" alt="" />
      </div>
      <div className="slider-web-image-top">
        <img src={images.web_logo_new} className="web_logo_new" alt="" />
      </div>
      <Slider {...settings}>
        {sliderImages &&
          sliderImages.map((image, index) => (
            <div key={index} className="slide">
              <img src={image} alt={`Slide ${index + 1}`} /> 
            </div>
          ))}
      </Slider>
    </div>
  );
};

export default HomeSlider;
