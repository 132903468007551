import React from "react";
import "./Testimonies.css";
import { images } from "../../constants";
import Slider from "react-slick";

const Testimonies = () => {
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0%",
    slidesToShow: 2.35, // Change this value to 1
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    // autoplay: true,
    // autoplaySpeed: 2500,
    responsive: [
      {
        breakpoint: 920, // Apply settings for mobile devices (max-width: 767px)
        settings: {
          slidesToShow: 2.3,
          centerPadding: "12%", // Remove padding on mobile
          // margin: "0 100px",
        },
      },
      {
        breakpoint: 620, // Apply settings for mobile devices (max-width: 767px)
        settings: {
          slidesToShow: 1,
          centerPadding: "20%", // Remove padding on mobile
          // margin: "0 100px",
        },
      },
      {
        breakpoint: 420, // Apply settings for mobile devices (max-width: 767px)
        settings: {
          slidesToShow: 1,
          centerPadding: "20%", // Remove padding on mobile
          // margin: "0 100px",
        },
      },
      // {
      //   breakpoint: 420, // Apply settings for mobile devices (max-width: 767px)
      //   settings: {
      //     slidesToShow: 2.4,
      //     centerPadding: "0", // Remove padding on mobile
      //     // margin: "0 100px",
      //   },
      // },
    ],
  };

  return (
    <div className="testimonies_main_wrapp">
      {/* <h4 className="testimonies_heading">TESTIMONIES</h4> */}
      <h4 className="testimonies_heading">TESTIMONIALS</h4>

      {/* slider start */}
      <div className="testimonies_slider_wrapp">
        {/* <Slider {...settings}> */}
          {/* single card */}
          <div className="testimonies_signle_card">
            <div className="testimonies_card_side_line"></div>
            <div className="testimonies_card_inner_wrapp">
              <img src={images.somubhai} className="testimonies_images" alt="" />
              <div className="testimonies_card_name_wrapp">
                <h4>Somu Desai</h4>
                <h4>Artist</h4>
                <p>
                  Somu Desai, born in 1969 and raised in the serene surroundings
                  of Pardi, found inspiration in the simplicity of life and
                  nature. His art reflects the fusion of rural and urban
                  experiences, using industrial materials to tell a personal
                  narrative.
                </p>
              </div>
            </div>
            <div className="testimonies_card_side_line"></div>
          </div>
          {/* single card */}
          {/* <div className="testimonies_signle_card">
            <div className="testimonies_card_side_line"></div>
            <div className="testimonies_card_inner_wrapp">
              <img src={images.Tyeb_Mehta} alt="" />
              <div className="testimonies_card_name_wrapp">
                <h4>Tyeb Mehta</h4>
                <h4>Artist</h4>
                <p>
                  Tyeb Mehta, born on 26 July 1925 in Kapadvanj, Gujarat, India,
                  was a prominent Indian artist. He was a member of the Bombay
                  Progressive Artists' Group, drawing inspiration from Western
                  Modernism and leaving a lasting impact on Indian paintings.
                </p>
              </div>
            </div>
            <div className="testimonies_card_side_line"></div>
          </div> */}
          {/* single card */}
          {/* <div className="testimonies_signle_card">
            <div className="testimonies_card_side_line"></div>
            <div className="testimonies_card_inner_wrapp">
              <img src={images.S_H_Raza} alt="" />
              <div className="testimonies_card_name_wrapp">
                <h4>S H Raza</h4>
                <h4>Artist</h4>
                <p>
                  Sayed Haider Raza (22 February 1922 – 23 July 2016) was an
                  eminent Indian painter known for his association with France
                  and his significant contributions to Indian art.
                </p>
              </div>
            </div>
            <div className="testimonies_card_side_line"></div>
          </div> */}
          {/* single card */}
          {/* <div className="testimonies_signle_card">
            <div className="testimonies_card_side_line"></div>
            <div className="testimonies_card_inner_wrapp">
              <img src={images.m_f_husain} alt="" />
              <div className="testimonies_card_name_wrapp">
                <h4>Maqbool Fida Husain</h4>
                <h4>Artist</h4>
                <p>
                  Maqbool Fida Husain (17 September 1915 – 9 June 2011) was a
                  renowned Indian artist known for his vibrant, narrative
                  paintings in a modified Cubist style.
                </p>
              </div>
            </div>
            <div className="testimonies_card_side_line"></div>
          </div> */}
        {/* </Slider> */}
      </div>
      {/* slider end */}
      {/* partner section start  */}
      <div className="testimonies_partner_wrapp">
        <h5>Partners</h5>
        <div>
          <img src={images.BG_logo} style={{ width:'100px' }} />
          <img src={images.cwaty_new} style={{ width:'100px' }} />
          {/* <img src={images.Titan} /> */}
          {/* <img src={images.nike} /> */}
          {/* <img src={images.moma} /> */}
          {/* <img src={images.Tanishq} /> */}
          {/* <img src={images.times_of_India} /> */}
        </div>
      </div>
     {/* partner section end */}
    </div>
  );
};

export default Testimonies;
