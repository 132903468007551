import React, { useEffect, useState } from "react";
import "./Collector.css";
import Slider from "react-slick";
import { images } from "../../constants";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Slide from "react-reveal/Slide";
import { Link } from "react-router-dom";
import { useScroll } from "../../ScrollContext";

const items2 = [
  {
    id: 1,
    name: "Curate",
    content:
      "Dive into a treasure trove of Rarerezs, each representing a unique piece of art or collectible. Curate your digital collection with rare and valuable assets that resonate with your passion and style.",
    img: images.Collect,
  },
  {
    id: 2,
    name: "Collect",
    content:
      "Own a piece of history and immerse yourself in the world of digital marvels. As an rarerez® collector, you become a custodian of legacy, cherishing remarkable pieces that hold significant value.",
    img: images.Collect,
  },
  {
    id: 3,
    name: "Capitalize",
    content:
      " Watch your Rarerezs appreciate in value over time, unlocking new opportunities for financial growth and investment. Your collection becomes a timeless asset with the potential for substantial returns.",
    img: images.Collect,
  },
  {
    id: 4,
    name: "Curate",
    content:
      "Dive into a treasure trove of Rarerezs, each representing a unique piece of art or collectible. Curate your digital collection with rare and valuable assets that resonate with your passion and style.",
    img: images.Collect,
  },
  {
    id: 5,
    name: "Collect",
    content:
      "Own a piece of history and immerse yourself in the world of digital marvels. As an rarerez® collector, you become a custodian of legacy, cherishing remarkable pieces that hold significant value.",
    img: images.Collect,
  },
  {
    id: 6,
    name: "Capitalize",
    content:
      " Watch your Rarerezs appreciate in value over time, unlocking new opportunities for financial growth and investment. Your collection becomes a timeless asset with the potential for substantial returns.",
    img: images.Collect,
  },

  // Add more items2 as needed
];

const Collector = () => {
  const [isVisible2, setisVisible2] = useState(false);
  const { setScrollTo2 } = useScroll();

  const [currentSlide, setCurrentSlide] = useState(0);

  const CustomPrevArrow2 = (props) => {
    const { onClick } = props;
    return (
      <button className={`custom-arrow2 custom-prev-arrow2`} onClick={onClick}>
        <img
          src={images.left_arrow}
          style={{
            width: "30px",
            height: "30px",
            objectFit: "contain",
            zIndex: 11,
          }}
          alt="Previous"
        />
      </button>
    );
  };

  const CustomNextArrow2 = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    return (
      <div className={`custom-arrow2 custom-next-arrow2`} onClick={onClick}>
        <img
          src={images.right_arrow}
          style={{ width: "30px", height: "30px", objectFit: "contain" }}
          alt="Next"
        />
      </div>
    );
  };

  useEffect(() => {
    setisVisible2(true);
  }, []);

  const handleExitAnimation = () => {
    setisVisible2(false);
  };

  const [centerSlide, setCenterSlide] = useState(0);
  const settings = {
    centerMode: true,
    centerPadding: "4%",
    slidesToShow: 3,
    speed: 1000,
    focusOnSelect: true,
    arrows: false,
    prevArrow: <CustomPrevArrow2 />,
    nextArrow: <CustomNextArrow2 />,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          // slidesToShow: 1,
          // slidesToScroll: 1,
          // initialSlide: 1,
          arrows: true,
        },
      },
    ],
  };
  return (
    <div className="collector_main_wrapp">
      <div className="howitwork2_img_wrapp"></div>
      {/* <div className="collector_center_img"></div> */}
      <Link
        to="/"
        onClick={() => {
          setScrollTo2(4);
        }}
      >
        <Slide left>
          <div className="collector_floting_name_wrapp">
            <h4 className="collector_floting_name collector_active">
              COLLECTOR
            </h4>
            <BsArrowRightShort
              style={{ color: "var(--color-white)" }}
              size={34}
            />
          </div>
        </Slide>
      </Link>
      <Link to={"/Creator"}>
        <Slide left>
          <div className="collector_floting_name_wrapp2">
            <BsArrowLeftShort
              style={{ color: "var(--color-main)" }}
              size={34}
            />
            <h4 className="collector_floting_name">CREATOR</h4>
          </div>
        </Slide>
      </Link>

      <Slide left>
        <div className="collector_slider_main_wrapp">
          <Slider {...settings}>
            {items2.map((item2, index) => (
              <div key={item2.id} className="collector_slide_item">
                <div className="collector_slide_inner">
                  <img src={item2.img} alt="" className="collector_slide_img" />
                  <h2
                    style={{
                      fontFamily: "var(--font-600)",
                      color: "red",
                      fontSize: "30px",
                      width: "90%",
                    }}
                  >
                    {item2.name}
                  </h2>
                  <div className="collector_slide_description">
                    <p>{item2.content}</p>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </Slide>
    </div>
  );
};

export default Collector;
