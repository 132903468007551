import React from "react";
import "./MenuBar.css";
import { IoClose } from "react-icons/io5";
import { AiFillTwitterCircle } from "react-icons/ai";
import { BsFacebook } from "react-icons/bs";
import images from "../../constants/images";
import { Link } from "react-router-dom";
import { Slide } from "react-reveal";
import { useScroll } from "../../ScrollContext";

const MenuBar = ({ setMenu, getmenu }) => {
  const { setScrollTo } = useScroll();

  return (
    <>
      <Slide top>
        <div className="menubar_main_wrapp">
          <div className="menubar_logo_wrapp">
            <img
              src={images.rarerez_white}
              style={{ height: "40px", objectFit: "contain" }}
            />
            {/* <span className="logo_div">rarerez®</span> */}
            <button
              onClick={() => {
                setMenu(!getmenu);
                console.log(getmenu);
              }}
            >
              <IoClose size="40" color="white" />
            </button>
          </div>
          <div className="menubar_links_wrapp">
            <Link
              className="menu_link_bold"
              // state={{ scrollto: 2 }}
              onClick={() => {
                setMenu(false);
                setScrollTo(4);
              }}
              to={"/"}
            >
              ABOUT US
            </Link>
            <Link
              className="menu_link_bold"
              onClick={() => {
                setMenu(false);
                setScrollTo(6);
              }}
              to={"/"}
            >
              THE APP
            </Link>
            <Link
              className="menu_link_bold"
              onClick={() => {
                setMenu(false);
                setScrollTo(3);
              }}
              to={"/"}
            >
              CREATOR COLLECTOR
            </Link>
            <Link
              className="menu_link_bold"
              onClick={() => {
                setMenu(false);
                setScrollTo(7);
              }}
              to={"/"}
            >
              HOW IT WORKS
            </Link>
            <Link
              className="menu_link_bold"
              onClick={() => {
                setMenu(false);
                setScrollTo(9);
              }}
              to={"/"}
            >
              TESTIMONIALS
            </Link>
            <Link
              className="menu_link_bold"
              onClick={() => {
                setMenu(false);
                // setScrollTo(9);
              }}
              to={"/TechSpecs"}
            >
              Tech Specifications
            </Link>
            {/* <Link className="menu_link_bold">ASK CWATY</Link>
            <Link className="menu_link_light">FAQ</Link> */}
            <Link
              className="menu_link_light"
              to="/blog"
              onClick={() => {
                setMenu(false);
              }}
            >
              BLOG
            </Link>
            <Link
              className="menu_link_light"
              to="/termsofuse"
              onClick={() => {
                setMenu(false);
              }}
            >
              TERM OF USE
            </Link>
            <Link
              className="menu_link_light"
              to="/privacy_policy"
              onClick={() => {
                setMenu(false);
              }}
            >
              PRIVACY POLICY
            </Link>

            <Link
              className="menu_link_light"
              to="/refundandcancel"
              onClick={() => {
                setMenu(false);
              }}
            >
              Refund and Cancellation Policy
            </Link>
            <Link
              className="menu_link_light"
              to="/ourteam"
              onClick={() => {
                setMenu(false);
              }}
            >
              Our Team
            </Link>
            <Link
              className="menu_link_light"
              to="/contact"
              onClick={() => {
                setMenu(false);
              }}
            >
              Let's Connect
            </Link>
          </div>
          <div className="social_wrapp">
            <div className="social_icons_wrapp">
              <BsFacebook color="white" size={24} />
              <img
                src={images.instagram}
                style={{
                  filter: "hue-rotate(90deg)",
                  height: "26px",
                  objectFit: "contain",
                  //   background: "white",
                }}
              />
              <AiFillTwitterCircle color="white" size={28} />
            </div>
            <p className="menu_copyright_text">
              Copyright&#169;2024 rarerez.com All right reserved.
            </p>
          </div>
        </div>
      </Slide>
    </>
  );
};

export default MenuBar;
