import React from "react";
import "./AboutUs.css";
// import { MouseParallax, ScrollParallax } from "react-just-parallax";
import { Slide } from "react-reveal";

const AboutUs = () => {
  return (
    <div className="about_main_wrapp">
      <div className="about_img_wrapp"> </div>
      <div className="about_text_wrapp">
        {/* <Slide top> */}
        {/* <p className="about_text1">ABOUT US</p> */}
        {/* <p>I'm reacting to mouse move</p> */}

        <p className="about_text2">Welcome to rarerez.com</p>

        <p className="about_text3">
          A visionary platform revolutionizing the art and collectibles
          industry. Our ethos is centered on empowering creators and enriching
          collectors, fostering a dynamic ecosystem where brilliance meets
          passion.
        </p>
        <p className="about_text3">
          Our company is driven by a team of experts in art, entertainment, and
          technology, united by a shared vision of transforming the world of
          collectibles. We are committed to providing the highest level of
          service and ensuring the authenticity and validation of each rarerez®
          in our community.
        </p>
        <p className="about_text3">
          rarerez.com is more than just a platform; it's a space where creators
          can showcase their talents, collectors can discover unique treasures,
          and enthusiasts can connect and grow together.
        </p>
        {/* <p className="about_text3">
          We're dedicated to providing the highest level of service to our
          users. from the authentication and validation of each bond. to the
          user-friendly interface of our platform.
          <br />
          Our goal is to make rarerez.com the go-to destination for anyone looking
          to discover and invest in rare and valuable products.
        </p>
        <p className="about_text3">
          We invite you to join us on this journey and discover the future of
          art trading and unique experiences. With rarerez.com, you can invest in
          your future and own a piece of history.
        </p> */}
        {/* </Slide> */}
      </div>
    </div>
  );
};

export default AboutUs;
