import React, { useState } from "react";
import "./Navbar.css";
import images from "../../constants/images";
import { Link } from "react-router-dom";

const Navbar = ({ setMenu, getmenu }) => {
  // const [getmenu, setMenuu] = useState(false);

  return (
    <div className="navbar_main_wrapp">
      <div className="navbar_base_wrapp">
        <Link to="/rarerez" className="logo_div">
        <img
          src={images.rarerez}
          style={{ height: "40px", objectFit: "contain" }}
          />
          </Link>
        <button
          onClick={() => {
            setMenu(!getmenu);
            console.log(getmenu);
          }}
        >
          <img
            src={images.list}
            style={{ height: "40px", objectFit: "contain" }}
          />
        </button>
      </div>
    </div>
  );
};

export default Navbar;
