import React, { useState, useEffect } from "react";
import "./CreatorAndCollector.css";
import {
  HiOutlineArrowLongLeft,
  HiOutlineArrowLongRight,
} from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";

import Slider from "react-slick";
import { images } from "../../constants";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import Slide from "react-reveal/Slide";
import { useScroll } from "../../ScrollContext";
import PageTransition from "react-router-page-transition";

//
const items = [
  {
    id: 1,
    name: "Protect",
    content:
      "Our platform uses advanced blockchain technology to ensure that each creation is securely stored and protected from duplication or tampering. Your brilliance is preserved for generations to come",
    img: images.Profit,
  },
  {
    id: 2,
    name: "Promote",
    content:
      "rarerez.com provides a stage for you to tell the story behind your creations, connecting you with a community of passionate enthusiasts who appreciate your talent and artistry.",
    img: images.Profit,
  },
  {
    id: 3,
    name: "Profit",
    content:
      " As an rarerez® creator, you participate in a groundbreaking royalty structure, earning ongoing rewards with each subsequent sale of your digital assets. Monetize your creations like never before.      ",
    img: images.Profit,
  },
  {
    id: 4,
    name: "Protect",
    content:
      "Our platform uses advanced blockchain technology to ensure that each creation is securely stored and protected from duplication or tampering. Your brilliance is preserved for generations to come",
    img: images.Profit,
  },
  {
    id: 5,
    name: "Promote",
    content:
      "rarerez.com provides a stage for you to tell the story behind your creations, connecting you with a community of passionate enthusiasts who appreciate your talent and artistry.",
    img: images.Profit,
  },
  {
    id: 6,
    name: "Profit",
    content:
      " As an rarerez® creator, you participate in a groundbreaking royalty structure, earning ongoing rewards with each subsequent sale of your digital assets. Monetize your creations like never before.      ",
    img: images.Profit,
  },

  // Add more items as needed
];
//
//
const items2 = [
  {
    id: 1,
    name: "Curate",
    content:
      "Dive into a treasure trove of Rarerezs, each representing a unique piece of art or collectible. Curate your digital collection with rare and valuable assets that resonate with your passion and style.",
    img: images.Collect,
  },
  {
    id: 2,
    name: "Collect",
    content:
      "Own a piece of history and immerse yourself in the world of digital marvels. As an rarerez® collector, you become a custodian of legacy, cherishing remarkable pieces that hold significant value.",
    img: images.Collect,
  },
  {
    id: 3,
    name: "Capitalize",
    content:
      " Watch your Rarerezs appreciate in value over time, unlocking new opportunities for financial growth and investment. Your collection becomes a timeless asset with the potential for substantial returns.",
    img: images.Collect,
  },
  {
    id: 4,
    name: "Curate",
    content:
      "Dive into a treasure trove of Rarerezs, each representing a unique piece of art or collectible. Curate your digital collection with rare and valuable assets that resonate with your passion and style.",
    img: images.Collect,
  },
  {
    id: 5,
    name: "Collect",
    content:
      "Own a piece of history and immerse yourself in the world of digital marvels. As an rarerez® collector, you become a custodian of legacy, cherishing remarkable pieces that hold significant value.",
    img: images.Collect,
  },
  {
    id: 6,
    name: "Capitalize",
    content:
      " Watch your Rarerezs appreciate in value over time, unlocking new opportunities for financial growth and investment. Your collection becomes a timeless asset with the potential for substantial returns.",
    img: images.Collect,
  },

  // Add more items as needed
];
//
const CreatorAndCollector = () => {
  // creator
  const [isVisible, setIsVisible] = useState(false);
  const { setScrollTo } = useScroll();
  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button className={`custom-arrow2 custom-prev-arrow2`} onClick={onClick}>
        <img
          src={images.left_arrow}
          style={{
            width: "30px",
            height: "30px",
            objectFit: "contain",
            zIndex: 11,
          }}
          alt="Previous"
        />
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    return (
      <div className={`custom-arrow2 custom-next-arrow2`} onClick={onClick}>
        <img
          src={images.right_arrow}
          style={{ width: "30px", height: "30px", objectFit: "contain" }}
          alt="Next"
        />
      </div>
    );
  };
  useEffect(() => {
    setIsVisible(true);
  }, []);

  const handleExitAnimation = () => {
    setIsVisible(false);
  };
  const [centerSlide, setCenterSlide] = useState(0);

  const settings = {
    centerMode: true,
    centerPadding: "4%",
    slidesToShow: 3,
    speed: 1000,
    focusOnSelect: true,
    arrows: false,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          // slidesToShow: 1,
          // slidesToScroll: 1,
          // initialSlide: 1,
          arrows: true,
        },
      },
    ],
  };

  const appear = {
    from: 0,
    to: 1,
    attributeName: "opacity",
  };

  const leave = {
    steps: [
      {
        style: {
          transform: "translateX(0)",
        },
      },
      {
        duration: 500,
        style: {
          transform: "translateX(300)",
          height: 50,
        },
      },
      {
        duration: 1000,
        style: {
          height: 0,
        },
      },
    ],
  };

  // creator end

  const [isSliding, setIsSliding] = useState(false);
  const [slideDirection, setSlideDirection] = useState("left");
  const [redFlex, setRedFlex] = useState(1);
  const [blackFlex, setBlackFlex] = useState(1);
  const [getid, setId] = useState(0);
  const navigate = useNavigate();

  const handleButtonClick = (direction) => {
    setIsSliding(true);
    setSlideDirection(direction);

    // Set flex values based on direction
    if (direction === "left") {
      setRedFlex(1);
      setBlackFlex(0);
    } else {
      setRedFlex(0);
      setBlackFlex(1);
    }
  };

  useEffect(() => {
    if (isSliding) {
      setTimeout(() => {
        setIsSliding(false);
        navigate(slideDirection === "left");
      }, 1000); // 2.0 seconds (2000 milliseconds) for the sliding time
    }
  }, [isSliding, slideDirection, navigate]);

  //   collection start
  const [isVisible2, setisVisible2] = useState(false);
  const { setScrollTo2 } = useScroll();

  const [currentSlide, setCurrentSlide] = useState(0);

  const CustomPrevArrow2 = (props) => {
    const { onClick } = props;
    return (
      <button className={`custom-arrow2 custom-prev-arrow2`} onClick={onClick}>
        <img
          src={images.left_arrow}
          style={{
            width: "30px",
            height: "30px",
            objectFit: "contain",
            zIndex: 11,
          }}
          alt="Previous"
        />
      </button>
    );
  };

  const CustomNextArrow2 = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    return (
      <div className={`custom-arrow2 custom-next-arrow2`} onClick={onClick}>
        <img
          src={images.right_arrow}
          style={{ width: "30px", height: "30px", objectFit: "contain" }}
          alt="Next"
        />
      </div>
    );
  };

  useEffect(() => {
    setisVisible2(true);
  }, []);

  const handleExitAnimation2 = () => {
    setisVisible2(false);
  };

  const [centerSlide2, setCenterSlide2] = useState(0);
  const settings2 = {
    centerMode: true,
    centerPadding: "4%",
    slidesToShow: 3,
    speed: 1000,
    focusOnSelect: true,
    arrows: false,
    prevArrow: <CustomPrevArrow2 />,
    nextArrow: <CustomNextArrow2 />,
    responsive: [
      {
        breakpoint: 620,
        settings: {
          // slidesToShow: 1,
          // slidesToScroll: 1,
          // initialSlide: 1,
          arrows: true,
        },
      },
    ],
  };
  // collector end
  return (
    <>
      {getid === 1 ? (
        <Slide left>
          <div className="creator_div">
            <div className="creator_main_wrapp">
              <div className="creator_img_wrapp"></div>
              <Link
                to="/"
                onClick={() => {
                  setId(0);
                }}
              >
                <Slide right>
                  <div className="creator_floting_name_wrapp">
                    <BsArrowLeftShort
                      style={{ color: "var(--color-white)" }}
                      size={34}
                    />
                    <h4 className="creator_active">CREATOR</h4>
                  </div>
                </Slide>
              </Link>
              <Link
                onClick={() => {
                  setId(2);
                }}
              >
                <Slide left>
                  <div className="creator_floting_name_wrapp2">
                    <h4 className="creator_floting_name">COLLECTOR</h4>
                    <BsArrowRightShort
                      style={{ color: "var(--color-black)" }}
                      size={34}
                    />
                  </div>
                </Slide>
              </Link>
              <Slide right>
                <div className="creator_slider_main_wrapp">
                  <Slider {...settings}>
                    {items.map((item, index) => (
                      <div key={item.id} className="creator_slide_item">
                        <div className="creator_slide_inner">
                          <img
                            src={item.img}
                            alt=""
                            className="creator_slide_img"
                          />
                          <h2
                            style={{
                              fontFamily: "var(--font-600)",
                              color: "white",
                              fontSize: "30px",
                              width: "90%",
                            }}
                          >
                            {item.name}
                          </h2>
                          <div className="creator_slide_description">
                            <p>{item.content}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Slide>
            </div>
          </div>
        </Slide>
      ) : getid === 2 ? (
        <Slide right>
          <div className="collector_div">
            <div className="collector_main_wrapp">
              <div className="howitwork2_img_wrapp"></div>
              {/* <div className="collector_center_img"></div> */}
              <Link
                to="/"
                onClick={() => {
                  setId(0);
                }}
              >
                <Slide left>
                  <div className="collector_floting_name_wrapp">
                    <h4 className="collector_floting_name collector_active">
                      COLLECTOR
                    </h4>
                    <BsArrowRightShort
                      style={{ color: "var(--color-white)" }}
                      size={34}
                    />
                  </div>
                </Slide>
              </Link>
              <Link
                onClick={() => {
                  setId(1);
                }}
              >
                <Slide left>
                  <div className="collector_floting_name_wrapp2">
                    <BsArrowLeftShort
                      style={{ color: "var(--color-main)" }}
                      size={34}
                    />
                    <h4 className="collector_floting_name">CREATOR</h4>
                  </div>
                </Slide>
              </Link>

              <Slide left>
                <div className="collector_slider_main_wrapp">
                  <Slider {...settings2}>
                    {items2.map((item2, index) => (
                      <div key={item2.id} className="collector_slide_item">
                        <div className="collector_slide_inner">
                          <img
                            src={item2.img}
                            alt=""
                            className="collector_slide_img"
                          />
                          <h2
                            style={{
                              fontFamily: "var(--font-600)",
                              color: "red",
                              fontSize: "30px",
                              width: "90%",
                            }}
                          >
                            {item2.name}
                          </h2>
                          <div className="collector_slide_description">
                            <p>{item2.content}</p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                </div>
              </Slide>
            </div>
          </div>
        </Slide>
      ) : (
        <div className="candc_main_wrapp">
          <div
            className="candc_red_wrapp"
            style={{ flex: redFlex }}
            // onClick={() => handleButtonClick("left")}
          >
            <div className="candc_red_img_wrapp"></div>
            <Link
              onClick={() => {
                setId(1);
              }}
              className="candc_red_text_wrapp"
            >
              <HiOutlineArrowLongLeft
                style={{ color: "var(--color-white)" }}
                size={34}
                className="candc_arrow"
              />
              <h2>CREATOR</h2>
            </Link>
          </div>
          <div
            className="candc_black_wrapp"
            style={{ flex: blackFlex, zIndex: blackFlex === 1 ? 999999 : 0 }}
            // onClick={() => handleButtonClick("right")}
          >
            <div className="candc_black_img_wrapp"></div>
            <Link
              onClick={() => {
                setId(2);
              }}
              className="candc_black_text_wrapp"
            >
              <h2>COLLECTOR</h2>
              <HiOutlineArrowLongRight
                style={{ color: "var(--color-white)" }}
                size={34}
                className="candc_arrow"
              />
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export default CreatorAndCollector;
