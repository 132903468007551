import React from "react";
import "./termsofuse.css";
import { images } from "../../constants";
import Slider from "react-slick";
import CardView from "../../components/Cardview";

const Termsuse = () => {
  return (
    <div className="termsofuse_main_wrapp">
      <div className="termsofuse_main_wrapp_heading">
        <b className="h1_terms">Terms of Use for rarerez®</b> <br />
        <br />
        <span className="terms_date">Effective Date: 23/08/2023</span>
        <br />
        <br />
        Welcome to rarerez® ("we", "our", or "us"). Please read these Terms of
        Use ("Terms") carefully before using the rarerez® website (the "Site")
        and mobile application (the "App") collectively referred to as the
        "Platform". By accessing or using the Platform, you agree to be bound by
        these Terms and our Privacy Policy. If you do not agree with these
        Terms, please do not access or use the Platform.
        <br /> <br />
        <p>1. Acceptance of Terms</p>
        <br />
        By using the Platform, you acknowledge that you have read, understood,
        and agreed to be bound by these Terms. If you do not agree to these
        Terms, you are not authorized to use the Platform.
        <br />
        <br />
        <p>2. Eligibility</p>
        <br />
        You must be at least 18 years old to use the Platform. By using the
        Platform, you represent and warrant that you are at least 18 years old
        and have the legal capacity to enter into these Terms.
        <br />
        <br />
        <p>3. Use of Platform</p>
        <br />
        <p>
          (a) You agree to use the Platform in compliance with all applicable
          laws and regulations.
        </p>
        <br />
        <p>
          (b) You are solely responsible for any content you post, submit, or
          share on the Platform, including text, images, and any other
          materials.
        </p>
        <br />
        <p>
          (c) You will not use the Platform to engage in any unlawful,
          fraudulent, or abusive behavior.
        </p>
        <br />
        <br />
        <p>4. User Accounts</p>
        <br />
        <p>
          (a) You may need to create an account to access certain features of
          the Platform. You agree to provide accurate and complete information
          during registration.
        </p>
        <br />
        <p>
          (b) You are responsible for maintaining the confidentiality of your
          account credentials and for all activities that occur under your
          account.
        </p>
        <br />
        <p>
          (c) You agree to immediately notify us of any unauthorized use of your
          account or any other breach of security.
        </p>
        <br />
        <br />
        <p>5. Content Ownership</p>
        <br />
        <p>
          (a) You retain ownership of the content you post on the Platform.
          However, by posting content, you grant us a non-exclusive,
          royalty-free, worldwide license to use, reproduce, modify, distribute,
          and display your content for the purpose of operating and promoting
          the Platform.
        </p>
        <br />
        <p>
          (b) We have the right to remove or modify any content that violates
          these Terms or is otherwise objectionable.
        </p>
        <br />
        <br />
        <p>6. Intellectual Property</p>
        <br />
        <p>
          (a) The Platform, including all content and intellectual property, is
          owned or licensed by us. You agree not to reproduce, distribute,
          modify, or create derivative works based on our content without our
          explicit consent.
        </p>
        <br />
        <p>
          (b) You may not use our name, trademarks, or logos without our prior
          written permission.
        </p>
        <br />
        <p>7. Privacy</p>
        <br />
        Your privacy is important to us. Please refer to our Privacy Policy for
        information on how we collect, use, and disclose your personal
        information.
        <br />
        <br />
        <p>8. Termination</p>
        <br />
        We reserve the right to terminate or suspend your account and access to
        the Platform at our sole discretion, without notice, for any reason,
        including violation of these Terms.
        <br />
        <br />
        <p>9. Disclaimers</p>
        <br />
        <p>
          (a) The Platform is provided "as is" and "as available" without any
          warranties of any kind, either express or implied.
        </p>
        <br />
        <p>
          (b) We do not warrant that the Platform will be error-free or
          uninterrupted.
        </p>
        <br />
        <br />
        <p>10. Limitation of Liability</p>
        <br />
        We shall not be liable for any indirect, incidental, special, or
        consequential damages arising out of or in connection with your use of
        the Platform.
        <br />
        <br />
        <p>11. Governing Law</p>
        <br />
        These Terms are governed by and construed in accordance with the laws of
        Mumbai Jurisdiction . Any disputes arising from these Terms shall be
        subject to the exclusive jurisdiction of the courts in Mumbai
        Jurisdiction.
        <br />
        <br />
        <p>12. Changes to Terms</p>
        <br />
        We reserve the right to modify or update these Terms at any time.
        Changes will be effective upon posting to the Platform.
        <br />
        <br />
        <p>13. Contact Us</p>
        <br />
        If you have any questions or concerns about these Terms, please contact
        us at <a href="mailto:legal@nfb.club">legal@nfb.club</a>
        <br />
        By using the rarerez® Platform, you signify your agreement to these
        Terms of Use.
        <br />
        <br />
        Last updated: 23/08/2023
      </div>
    </div>
  );
};

export default Termsuse;
