import React from "react";
import "./TheApp2.css";
import { Slide } from "react-reveal";

const TheApp2 = () => {
  return (
    <div className="theapp2_main_wrapp">
      <div className="theapp2_img_wrapp"> </div>

      <div className="theapp2_text_wrapp">
        {/* <Slide top> */}
        {/* <p className="theapp2_text1">rarerez.com App</p> */}
        <p className="theapp2_text2" style={{ color: "var(--color-main)" }}>
          Empowering Creators,
          <br /> Enriching Collectors
        </p>

        <p className="theapp2_text3">
          The rarerez.com app is the powerhouse behind our ecosystem. With a
          user-friendly interface and powerful features, the app brings the
          world of rare treasures to your fingertips.
        </p>
        <p className="theapp2_text3">
          For creators, the app streamlines the process of showcasing and
          monetizing the rarerez® of their creations. From protecting their
          creations on the blockchain to engaging with a global community of
          enthusiasts, rarerez.com opens doors to unparalleled opportunities
        </p>
        <p className="theapp2_text3">
          For collectors, the app becomes a gateway to discovery, enabling
          seamless curation and ownership of rarerez® . Explore diverse
          collections, bid on exclusive pieces, and capitalize on the growing
          value of your rarerez®.
        </p>
        {/* </Slide> */}
      </div>
    </div>
  );
};

export default TheApp2;
