import React from "react";
import "./ourteamhome.css";
import { images } from "../../constants";

const Ourteamhome = () => {
  const listItemStyle = {
    listStyle: "disc",
    marginLeft: "20px",
    display: "inline",
  };
  return (
    <div className="Ourteamhome_main_wrapp">
      <div className="Ourteamhome_main_wrapp_heading">
        {/* <b className="h1_ourteam">OUR STORY</b> <br /> */}
        <br />
        <br />
        <div className="team-listhome">
          <div className="list-1home">
            <img src={images.Cwaty} className="img-list-team-home" alt="" />
            <p className="team_name-home">Cwaty</p>
            <p className="team_art_name-home">Founder, CPO</p>
          </div>
        </div>
        <div className="team-list-home">
          <div className="list-1">
            <img src={images.jagrat} className="img-list-team-home2" alt="" />
            <p className="team_name">Jagrat Desai</p>
            <p className="team_art_name">CEO, Co-Founder</p>
          </div>
          <div className="list-1">
            <img src={images.somu} className="img-list-team-home2" alt="" />
            <p className="team_name">Somu Desai</p>
            <p className="team_art_name">Co-Founder, Art</p>
          </div>
          <div className="list-1">
            <img src={images.gokul} className="img-list-team-home2" alt="" />
            <p className="team_name">Gokul Nair</p>
            <p className="team_art_name">Co-Founder, CTO</p>
          </div>
        </div>
        <div className="team-list-home">
          <div className="list-1">
            <img src={images.KhiaWadhawan} className="img-list-team-home2" alt="" />
            <p className="team_name">Khia Wadhawan</p>
            <p className="team_art_name">COO</p>
          </div>
          <div className="list-1">
            <img src={images.somu} className="img-list-team-home2" alt="" />
            <p className="team_name">Manoj</p>
            <p className="team_art_name">CBO</p>
          </div>
          <div className="list-1">
            <img src={images.gokul} className="img-list-team-home2" alt="" />
            <p className="team_name">Ravi Nimavat</p>
            <p className="team_art_name">Project Head</p>
          </div>
        </div>
        <br />
      </div>
    </div>
  );
};

export default Ourteamhome;
