import React from "react";
import "./ourteam.css";
import { images } from "../../constants";

const Ourteam = () => {
  const listItemStyle = {
    listStyle: "disc",
    marginLeft: "20px",
    display: "inline",
  };
  return (
    <div className="ourteam_main_wrapp">
      <div className="ourteam_main_wrapp_heading">
        {/* <b className="h1_ourteam">OUR STORY</b> <br /> */}
        <h1 className="ourteam_date">
          Meet the Trailblazers behind rarerez.com Innovative Art Trading
          Platform
        </h1>
        <br />
        {/* <span className="ourteam_details">
          Unlock the Potential of Rare Treasures with rarerez®. Discover the
          Future of Art Trading on the Go. Join our Community of Enthusiasts and
          Make Your Collection Stand Out.
        </span> */}
        <br />
        <br />
        <div className="team-list">
          <div className="list-1">
            <img src={images.Cwaty} className="img-list-team" alt="" />
            <p className="team_name">Cwaty</p>
            <p className="team_art_name">Founder, CPO</p>
            <p className="team_details_art">
              Introducing CWATY, the world's first virtual animal influencer.
              CWATY is leading the way in using the power of social media,
              gaming, and entertainment to raise awareness and funds for animal
              welfare.
            </p>
          </div>

          {/* jagrat bhai */}
          <div className="list-1">
            <img src={images.jagrat} className="img-list-team" alt="" />
            <p className="team_name">Jagrat Desai</p>
            <p className="team_art_name">CEO, Co-Founder</p>
            <p className="team_details_art">
              Jagrat Desai, the brain behind rarerez®'s revolutionary digital
              collectibles platform. With over 2 decades of experience in the
              entertainment industry, Jagrat blends his creative and strategic
              prowess with a passion for storytelling, technology, and welfare.
              As the creator of Cwaty, the world's first virtual animal
              influencer for animal welfare, Jagrat brings a unique vision to
              rarerez®, helping artists and creators protect, promote, and
              profit from their legacies in the digital age. His innovative
              approach is transforming how we view and interact with digital art
              and collectibles.
            </p>
          </div>

          {/* manoj bhai */}
          <div className="list-1">
            <img
              src={images.manoj_bhai}
              className="img-list-team"
              alt=""
              style={{ objectPosition: "top" }}
            />
            <p className="team_name">Manoj Kumar Padincharchera</p>
            <p className="team_art_name">CBO</p>
            <p className="team_details_art">
              Competent business development and operations professional with
              diverse experience and proven leadership skills in Ecom, Retail,
              FMCG, Media, Telecom & Digital domains. Having worked with
              MNC’s,family run businesses and startups, he brings in the vital
              cross functional expertise to build the business ground up.
            </p>
          </div>

          {/* <div className="list-1">
            <img src={images.KhiaWadhawan} className="img-list-team" alt="" />
            <p className="team_name">Khia Wadhawan</p>
            <p className="team_art_name">COO</p>
            <p className="team_details_art">
              Khia Wadhawan, a seasoned content production professional with 15+
              years' experience, boasts PR expertise and startup background. As
              COO at rarerez®, her strategic, budgeting, and team management
              skills will bolster the platform's growth and operational
              excellence.
            </p>
          </div> */}
        </div>
        <div className="team-list">
          <div className="list-1">
            <img src={images.gokul} className="img-list-team" alt="" />
            <p className="team_name">Gokul Nair</p>
            <p className="team_art_name">Co-Founder, CTO</p>
            <p className="team_details_art">
              Meet Gokul Nair, the tech-savvy problem-solver and founder of
              Binary Geckos. With a passion for helping businesses and
              individuals grow, Gokul's expertise lies in branding, marketing,
              app development, and all IT solutions. A lifelong learner, Gokul
              is always striving to be a better version of himself and never
              settles for less.
            </p>
          </div>
          {/* <div className="list-1">
            <img src={images.somu} className="img-list-team" alt="" />
            <p className="team_name">Somu Desai</p>
            <p className="team_art_name">Art consultant</p>
            <p className="team_details_art">
              Somu Desai, an innovative artist from Pardi who is transforming
              the art world with his unique blend of industrial materials and
              digital innovation. Born in 1969 and a fine arts graduate from
              Gujarat, Somu's work reflects the contrast between his tranquil
              upbringing and later urbanized life. As the Artistic Director of
              GNAP - INDIA and co-founder of Nature Art Culture (NAC Studio), he
              organizes public projects, workshops, and international
              residencies, fostering global artistic connections. Now, as an art
              consultant for rarerez®, Somu is creating secure digital bonds for
              rare and valuable assets, helping artists protect, promote, and
              profit from their work in the digital age.
            </p>
          </div> */}

          <div className="list-1">
            <img src={images.ravi} className="img-list-team" alt="" />
            <p className="team_name">Ravi Nimavat</p>
            <p className="team_art_name">Project Manager</p>
            <p className="team_details_art">
              I'm Ravi Nimavat, a seasoned project manager with a passion for
              turning ideas into reality. With a background in strategic
              planning and a knack for efficient team collaboration, I've
              successfully overseen numerous projects from inception to
              completion. I thrive on challenges and am dedicated to delivering
              results that exceed expectations.
            </p>
          </div>
        </div>
        <br />
        {/* <div className="our_angels">
          <h1 className="team_name2">Our Angels</h1>
          <br />
          <p className="team_details_art">
            Our angel investors are a dream team of savvy individuals who have a
            knack for building, managing, and protecting assets. Whether it's
            constructing buildings, running motels, or securing wealth, these
            folks know a thing or two about making smart investments. And
            they're thrilled to be putting their money where their mouth is by
            backing rarerez® as we shake up the art and collectibles industry.
          </p>
          <br />
          <ul>
            <li className="li_name">Nilesh Patel</li>
            <li className="li_name">Mahesh Patel</li>
            <li className="li_name">Tushar Panchal</li>
          </ul>
          <br />
          <img src={images.img_21} className="img-list-team2" alt="" />
        </div> */}
      </div>
    </div>
  );
};

export default Ourteam;
