import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Collector, Creator, HomePage } from "./Pages";
import Home from "./Pages/Home";
import { Navbar } from "./common/Index";
import MenuBar from "./Pages/menubar/MenuBar";
import Blog from "./Pages/blog/Blog";
import Termsofuse from "./Pages/termsofuse/Termsofuse";
import Contact from "./Pages/contact/contactus";
import { images } from "./constants";
import Blogdetails from "./Pages/blogdetails/Blogdetails";
import Ourteam from "./Pages/ourteam/ourteam";
import Privacypolicy from "./Pages/privacypolicy/privacypolicy";
import Ourteamhome from "./Pages/ourteamhome/Ourteamhome";
import Refundandcancel from "./Pages/refundandcancel/refundandcancel";
import TechSpecs from "./Pages/techspecs/TechSpecs";

function App() {
  const [getmenu, setMenu] = useState(false);
  useEffect(() => {
    console.log("getmenu", getmenu);
  }, []);

  return (
    <BrowserRouter>
      <Navbar setMenu={setMenu} getmenu={getmenu} />

      {getmenu ? <MenuBar setMenu={setMenu} getmenu={getmenu} /> : null}
      {/* <Navbar /> */}
      <Routes>
        <Route path="/rarerez" element={<Home />} />
        <Route path="*" element={<Home />} />
        <Route path="/Collector" element={<Collector />} />
        <Route path="/Creator" element={<Creator />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blogdetails" element={<Blogdetails />} />
        <Route path="/termsofuse" element={<Termsofuse />} />
        <Route path="/privacy_policy" element={<Privacypolicy />} />
        <Route path="/refundandcancel" element={<Refundandcancel />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/ourteam" element={<Ourteam />} />
        <Route path="/ourteamhome" element={<Ourteamhome />} />
        <Route path="/TechSpecs" element={<TechSpecs />} />
      </Routes>
      {/* <div className="cwaty_logo_wrapp">
        <img
          src={images.cwaty_logo}
          style={{ width: "40px", height: "40px", borderRadius: "40px" }}
          alt=""
        />
        <p className="cwaty_logo_text">Ask Cwaty</p>
      </div> */}
    </BrowserRouter>
  );
}

export default App;
