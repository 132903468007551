import React from "react";
import "./HowItWorks.css";
import { Slide } from "react-reveal";

const HowItWorks = () => {
  return (
    <div className="howitwork_main_wrapp">
      <div className="howitwork_img_wrapp"> </div>

      <div className="howitwork_text_wrapp">
        {/* <Slide top> */}
        {/* <p className="howitwork_text1">Why Join rarerez.com</p> */}
        <p className="howitwork_text2">
          Embrace a Journey of Creativity, Community, and Transformation
        </p>

        <p className="howitwork_text3">
          Joining rarerez.com opens the door to a world of endless
          possibilities. By becoming part of our vibrant ecosystem, you gain
          access to:
        </p>
        <p className="howitwork_text3">
          <span>- </span> Exclusive Treasures: Discover rare and valuable
          rarerez® that define artistry and history.
        </p>
        <p className="howitwork_text3">
          <span>- </span> Thriving Community: Connect with creators, collectors,
          and enthusiasts who share your passion.
        </p>
        <p className="howitwork_text3">
          <span>- </span> Transformative Experience: Be at the forefront of a
          revolution that reshapes the future of art trading and collectibles.
        </p>
        {/* </Slide> */}
      </div>
    </div>
  );
};

export default HowItWorks;
