import React from "react";
import "./TheApp.css";
import { Slide } from "react-reveal";

const TheApp = () => {
  return (
    <div className="theapp_main_wrapp">
      <div className="theapp_img_wrapp"> </div>

      <div className="theapp_text_wrapp">
        {/* <Slide top> */}
        {/* <p className="theapp_text1">rarerez.com</p> */}
        <p className="theapp_text2" style={{ color: "var(--color-main)" }}>
          Future of Creators and Collectors
        </p>

        <p className="theapp_text3">
          At rarerez.com, we believe in the power of technology to redefine the
          way rare and valuable products are traded, giving everyone the
          opportunity to own a piece of history and future.
        </p>
        <p className="theapp_text3">
          Creators establish ownership and protect their creations through
          rarerez® on the blockchain, ensuring authenticity and long-term value
          preservation. Verified creators connect with a global audience of
          passionate collectors, expanding their reach and impact.
        </p>
        <p className="theapp_text3">
          Collectors experience a new dimension of curation, owning rarerez®
          that represent unique artistry and legacy. As the rarerez® appreciate
          in value, collectors unlock opportunities for financial growth and
          investment, creating a new era of collectibles.
        </p>
        {/* </Slide> */}
      </div>
    </div>
  );
};

export default TheApp;
