import React from "react";
import "./privacypolicy.css";
import { images } from "../../constants";

const Privacypolicy = () => {
  return (
    <div className="privacypolicy_main_wrapp">
      <div className="termsofuse_main_wrapp_heading">
        <b className="h1_terms">Privacy Policy</b> <br />
        <br />
        <b className="h1_terms">INTRODUCTION </b> <br />
        <br />
        <span>
          Quest for Rare & Extraordinary Stories owned by NFB Tech Platform Pvt
          Ltd (“rarerez®” “we” or “us” or “our”) respect the privacy of our
          users (“user” or “you”). Hence, we maintain the highest standards for
          secure transactions and user information privacy. Please read the
          following statement to learn about our information gathering and
          dissemination practices.
        </span>
        <br /> <br />
        {/* BY USING THE SERVICES, MOBILE APPLICATION, WEBSITE OR OTHER SERVICES,
        YOU INDICATE THAT YOU UNDERSTAND, AGREE AND CONSENT TO THIS PRIVACY
        POLICY. IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY,
        PLEASE DO NOT USE THIS SERVICES. YOU HEREBY PROVIDE YOUR UNCONDITIONAL
        CONSENT OR AGREEMENTS TO rarerez® AS PROVIDED UNDER SECTION 43A AND
        SECTION 72A OF INFORMATION TECHNOLOGY ACT, 2000. */}
        By using the services, mobile application, website, or other services,
        you indicate that you understand, agree, and consent to this Privacy
        Policy. If you do not agree with the terms of this Privacy Policy,
        please do not use these services. You hereby provide your unconditional
        consent or agreements to rarerez® as provided under Section 43A and
        Section 72A of the Information Technology Act, 2000.
        <br />
        <br />
        By providing Us Your Information or by making use of the facilities
        provided by the Services, Mobile and Desktop Application, Website or
        Other Services and Applications under name of and in association with
        rarerez®, you hereby consent to the collection, storage, processing and
        transfer of any or all of Your Personal Information and Non-Personal
        Information by us as specified under this Privacy Policy. You further
        agree that such collection, use, storage and transfer of Your
        Information shall not cause any loss or wrongful gain to You or any
        other person.
        <br />
        <br />
        This Privacy Policy is a contract between You and rarerez® . This
        Privacy Policy shall be read together with the respective Terms of Use
        or other terms and condition of rarerez® ’s Services.
        <br />
        <br />
        This Privacy Policy provides you with details about the manner in which
        your data is collected, stored & used by us. By using/accessing this
        website, applications as defined for business and/or personal use,
        indicate that the user understands, agrees and consents to this privacy
        policy.
        <br />
        <br />
        This Privacy Policy is designed to tell You about Our practices
        regarding collection, use, and disclosure of information or data that
        You may provide via this Services. Please be sure to read this entire
        Privacy Policy before using or submitting information to this Services.
        <br />
        <br />
        <b>AMENDMENT OF PRIVACY POLICY</b>
        <br />
        <br />
        Our Privacy Policy is subject to change at any time without notice and
        the same shall be updated and available on this website from time to
        time. Though every effort would be made to keep you informed of the
        changes, if any, to the policy, you are kindly advised to review the
        policy periodically.
        <br />
        <br />
        <b>COLLECTION OF YOUR INFORMATION</b>
        <br />
        <br /> <br />
        <p>
          1. &nbsp; Types of Information We Collect. We collect information
          about our users, which may be identifiable, pseudonymous, or anonymous
          (including aggregated and de-identified data), as described below.
        </p>
        <br />
        <p className="list_number">
          (a) Personally identifiable information, such as your name, shipping
          address, email address, and telephone number, and demographic
          information, such as your age, gender, hometown, and interests, that
          you voluntarily give to us when you register with the Site:{" "}
          <a href="www.rarerez.com">www.rarerez.com</a>
        </p>
        <br />
        <p className="list_number">
          (b) Information our servers automatically collect when you access the
          Site, such as your IP address, your browser type, your operating
          system, your access times, and the pages you have viewed directly
          before and after accessing the Site;
        </p>
        <br />
        <p className="list_number">
          (c) Financial information as collected by the third party payment
          gateway as per their terms and conditions, such as data related to
          your payment method (e.g. valid credit card number, card brand,
          expiration date) that they may collect when you purchase, order or
          request information about our services from the Site;
        </p>
        <br />
        <p className="list_number">
          (d) User information from social networking sites, such as your name,
          your social network username, location, gender, birth date, email
          address, profile picture,and public data for contacts, if you connect
          your account to such social networks;
        </p>
        <br />
        <p className="list_number">
          (e) Information from third parties, such as personal information or
          network friends, if you connect your account to the third party and
          grant the Site permission to access this information;
        </p>
        <br />
        <p className="list_number">
          (f) Personal and other information you may provide when entering
          contests or giveaways and/or responding to surveys;
        </p>
        <br />
        <p className="list_number">
          (g) We may use tracking tools like Google Analytics and browser
          cookies for collecting information about your usage of our website and
        </p>
        <br />
        <p className="list_number">
          (h) Geo-Location Information. We may request access or permission to
          and track location-based information from your device, either
          continuously or while you are using our website, to provide
          location-based services. If you wish to change our access or
          permissions, you may do so in your device’s settings.
        </p>
        <br />
        <p className="list_number">
          (i) Other Information. We also collect information and other data at
          other points in our Service where you voluntarily provide it or where
          we state that your information is being collected.
        </p>
        <br />
        <br />
        <p>
          2. Interactions with our Service. To provide our Service, analyze
          trends, enforce our Terms of Service, and make the Service more useful
          to you, we collect information (typically Anonymous Data) from you
          when you interact with our Service, including, but not limited to,
          your browser type, operating system, IP address and associated geo
          location, device ID, actions and clickstream data, referring/exit
          pages, and date/time stamps. We may also store this data in log files.
        </p>
        <br />
        <p>
          3. Cookies or Other Tracking Technologies. Like many online services,
          we use cookies to collect information. We may use both session cookies
          (which expire once you close your web browser) and persistent cookies
          (which stay on your computer until you delete them) to analyze how
          users interact with our Service, make improvements to our product
          quality, and provide users with a more personalized experience.
        </p>
        <br />
        <br />
        <p>
          4. We engage with third-party services ("Third Party Services"),
          including Google, to help collect some of the information referred to
          above. These Third Party Services, acting on our behalf, may collect
          information about your use of our Service through their own cookies or
          other technologies ("Analytics Information"). The Third Party
          Services’ ability to use and share Analytics Information is restricted
          by such Third Party Services’ terms of use and privacy policy.
        </p>
        <br />
        <br />
        <p>
          5. Information Collected By Third-Party Companies to authenticate. We
          may receive information about you or related to you or your wallet
          address from service providers and other sources/companies that offer
          their products and/or services to us or to you, for use in conjunction
          with our Service, or whose products and/or services may be linked from
          our Service. We may add this to the data we have already collected
          from or about you through our Service. This information may include,
          for example:
        </p>
        <br />
        <p className="list_number">
          (a) That third-party wallet providers that provide us with your
          blockchain wallet address and certain other information you choose to
          share with those wallet providers;
        </p>
        <br />
        <p className="list_number">
          (b) That data analytics providers or vendors who provide us
          information related to certain blockchain wallet addresses;
        </p>
        <br />
        <p className="list_number">
          (c) That vendors who provide communication and identity verification
          services to us and which you choose to use; and other vendors who
          provide us information necessary to run our business or enforce our
          Terms of Service.
        </p>
        <br />
        <p className="list_number">
          (d) That the users on board will have to perform a adhar kyc for the
          authentication and safety purpose and shall be liable to submit the
          details identifiable or anonymous for the same as per the mandate of
          law.
        </p>
        <br />
        <p>
          Public Information. We collect data from activity and information that
          is publicly visible and/or accessible on other public sources. This
          may include, for example, information regarding your social media
          activity (e.g. Instagram, facebook,news articles, etc) purchases,
          sales, or transfers which may then be associated with other data you
          have provided to us.
        </p>
        <br />
        <b>
          PURPOSE FOR COLLECTING, USING, STORING AND PROCESSING OF INFORMATION
        </b>
        <br />
        <br />
        <p>
          6. rarerez® collects, uses, stores and processes Your Information for
          any purpose as may be permissible under applicable laws (including
          where the applicable law provides for such collection, usage, storage
          or processes in accordance with the consent of the user) and shall
          include the following:
        </p>
        <br />
        <p className="list_number">
          (a) That to facilitate Your use of the Services;
        </p>
        <br />
        <p className="list_number">
          (b) That to respond to Your inquiries or fulfil Your requests for
          information about the various services offered on the Services;
        </p>
        <br />
        <p className="list_number">
          (c) That to provide You with information about services available on
          the Services and to send You information, materials, including any
          email alerts, transactions with Us and competitions and promotions
          that you take part in and offers, if any.
        </p>
        <br />
        <p className="list_number">
          (d) That to send You important information regarding the Services,
          changes in terms and conditions, user agreements, and policies and/or
          other administrative information;
        </p>
        <br />
        <p className="list_number">
          (e) That send you creator, new artist update, newsletters, promotional
          materials and event information, and other notices related to our
          Service or third parties’ goods and services;
        </p>
        <br />
        <p className="list_number">
          (f) That comply with applicable laws, cooperate with investigations by
          law enforcement or other authorities of suspected violations of law,
          and/or to protect our and our Affiliates’ legal rights; and
        </p>
        <br />
        <p className="list_number">
          (g) That act in any other way which we have communicated to you and to
          which you have consented or we may describe when you provide your
          information.
        </p>
        <br />
        <br />
        <p>
          7. We may create anonymized records from identifiable information. We
          use this data for the same purposes as outlined above, including to
          improve our Service. We reserve the right to use and/or disclose
          anonymized information for any purpose. Disclosure of Your
          Information.
        </p>
        <br />
        <br />
        <p>
          8. We disclose your information and information about you as described
          below:
        </p>
        <br />
        <p className="list_number">
          (a) Third Party Service Providers. We may share your information and
          information about you with third party service providers to: provide
          technical infrastructure services; conduct quality assurance testing;
          analyze how our Service is used; prevent, detect, and respond to
          unauthorized activities or potential violations of our Terms of
          Service or policies; provide technical and customer support; and/or to
          provide other support to you, us, and/or to the Service.
        </p>
        <br />
        <p className="list_number">
          (b) Affiliates. We may share some or all of your information and
          information about you with any subsidiaries, joint ventures, or other
          companies or products under our common control ("Affiliates"), in
          which case we will require our Affiliates to honor this Privacy
          Policy.
        </p>
        <br />
        <p className="list_number">
          (c) Information Related to Your Public Activity. We may display or
          share information relating to your public activity, rarerez® , and/or
          apps ,websites, social media pages, news articles.
        </p>
        <br />
        <p className="list_number">
          (d) Legal Rights. Regardless of any choices you make regarding your
          information (as described below), rarerez® may disclose your
          information and information about you if it believes in good faith
          that such disclosure is necessary: (a) in connection with any legal
          investigation; (b) to comply with relevant laws or to respond to
          subpoenas, warrants, or other legal process served on rarerez® ; (c)
          to protect or defend the rights or property of rarerez® or users of
          the Service; and/or (d) to investigate or assist in preventing any
          violation or potential violation of the law, this Privacy Policy, or
          our Terms of Service.
        </p>
        <br />
        <b>STORAGE AND RETENTION INFORMATION</b>
        <br />
        <br />
        <p>
          9. That we store your data on online servers ensuring best possible
          protection from our end. We intend to protect it in encrypted format
          and also take back-up at defined intervals to ensure there is minimum
          data loss in case of any unfortunate server crash;
        </p>
        <br />
        <p>
          10. That we retain your information after we cease providing Services
          to you, even if you close your account, to the extent necessary to
          comply with our legal and regulatory obligations, and for the purpose
          of fraud monitoring, detection, and prevention and
        </p>
        <br />
        <p>
          11. That we also retain your information to comply with our tax,
          accounting, and financial reporting obligations, where we are required
          to retain the data by our contractual commitments to our financial
          partners, and where data retention is mandated by the payment methods
          that we support. Where we retain data, we do so in accordance with any
          limitation periods and records retention obligations that are imposed
          by applicable law.
        </p>
        <br />
        <b>Security</b>
        <br />
        <br />
        <p>
          12. That we care about the security of your information and use
          physical, administrative, and technological safeguards to preserve the
          integrity and security of information collected through our Service.
          However, no security system is impenetrable and we cannot guarantee
          the security of our systems, or those of our vendors. In the event
          that any information under our custody and control is compromised as a
          result of a breach of our security, we will take steps to investigate
          and remediate the situation and, in accordance with applicable laws
          and regulations, may notify those individuals whose information may
          have been compromised. The user agrees to indemnify the rarerez®. from
          any losses occurred due to any breach of security which may cause user
          any mental, physical, emotional or financial losses.
        </p>
        <br />
        <br />
        <p>
          13. You are responsible for the security of your digital wallet, and
          we urge you to take steps to ensure it is and remains secure. If you
          discover an issue related to your wallet, please contact on the given
          details below.
        </p>
        <br />
        <p>
          14. Minors. We do not intentionally gather information from visitors
          who are under the age of 18. Our Terms of Service require all users to
          be at least 18 years old. Minors who are at least 18 years old but are
          under 18 years old may use a parent or guardian’s account, but only
          with the supervision of the account holder. If a child under 18
          submits identifiable information to us and we learn that the
          identifiable information is the information of a child under 18, we
          will attempt to delete the information as soon as possible. If you
          believe that we might have any identifiable information from a child
          under 18, please contact us.
        </p>
        <br />
        <b>JURISDICTION</b>
        <br />
        <br />
        <p>
          15. If you choose to visit the website, your visit and any dispute
          over privacy is subject to this Policy and Terms and Conditions of
          Use. In addition to the foregoing, any disputes arising under this
          Policy shall be governed by the laws of India and non exclusive
          jurisdiction of the courts located at Mumbai, Maharashtra, India.{" "}
        </p>
        <br />
        <b>EMAILS AND COMMUNICATION</b>
        <br />
        <br />
        <p>
          16. If you no longer wish to receive correspondence, emails, or other
          communications from us, you may opt-out by:
        </p>
        <br />
        <p className="list_number">
          (a) Noting your preferences at the time you register your account with
          the Site;
        </p>
        <br />
        <p className="list_number">
          (b) Logging into your account settings and updating your preferences;
        </p>
        <br />
        <p className="list_number">
          (c) Contacting us using the contact information provided below or
        </p>
        <br />
        <p className="list_number">
          (d) If you no longer wish to receive correspondence, emails, or other
          communications from third parties, you are responsible for contacting
          the third party directly.
        </p>
        <br />
        <b>GRIEVANCE POLICY</b>
        <br />
        <br />
        <p>
          17. Any complaints, abuse or concerns with regards to the use,
          processing and disclosure of Information provided by you or breach of
          these terms should immediately be informed by mailing us at
          <a href="mailto::grievance@rarerez.com">grievance@rarerez.com</a> .
        </p>
        <br />
        Your privacy is extremely important to us. Upon acceptance of these
        Terms of Use, you confirm that you have read, understood and
        unequivocally accepted our Privacy Policy.
        <br />
        <br />
      </div>
    </div>
  );
};

export default Privacypolicy;
