import React from "react";
import "../termsofuse/termsofuse.css";

const TechSpecs = () => {
  return (
    <div className="termsofuse_main_wrapp">
      <div className="termsofuse_main_wrapp_heading">
        <p
          className=""
          style={{
            fontSize: "1.4rem",
            fontFamily: "var(--font-900)",
            color: "white",
            fontWeight: "900",
            marginBottom: "10px",
          }}
        >
          Introducing rarerez®: The Cutting-Edge Build with Exceptional Tech
          Specifications
        </p>
        <br />
        At rarerez®, we have developed a state-of-the-art platform that combines
        top-tier technologies to provide an unparalleled user experience. Our
        frontend is crafted with React.js, ensuring a dynamic and responsive
        interface that meets the highest standards of modern web development.
        <br /> <br />
        Security is at the core of rarerez®. Our custom security system is
        integrated directly into the React.js frontend, offering robust
        protection against potential threats. On the backend, we utilize
        Laravel, renowned for its elegant syntax and strong security features,
        ensuring that our server-side operations are both efficient and secure.
        <br />
        <br />
        To further enhance the security and integrity of our platform, we
        incorporate blockchain technology. This not only guarantees the highest
        level of data security but also adds an extra layer of transparency and
        trust for our users.
        <br />
        <br />
        <p>
          rarerez® is designed to offer a seamless, secure, and innovative
          experience, leveraging the best in modern technology. Join us and
          discover the future of secure, high-performance web solutions.
        </p>
        <br />
      </div>
    </div>
  );
};

export default TechSpecs;
