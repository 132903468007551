// logos,
import new_logo from "../assets/commons/new_logo.png";
import logo_white from "../assets/commons/logo_white.png";
import full_logo_white from "../assets/commons/full_logo_white.png";
import list from "../assets/commons/list.png";
import instagram from "../assets/commons/instagram.png";
import red_cube from "../assets/commons/red_cube.png";
import red_cube_gard from "../assets/commons/red_cube_gard.png";
import red_cube_with_hand from "../assets/commons/red_cube_with_hand.png";
import five_cubes from "../assets/commons/5_cubes.png";
import bonds from "../assets/commons/bonds.png";
import multi_red_cube from "../assets/commons/multi_red_cube.png";
import Relese_NFB from "../assets/commons/Relese_NFB.png";
import right_arrow from "../assets/commons/right_arrow.png";
import left_arrow from "../assets/commons/left_arrow.png";
import BG_2 from "../assets/commons/BG_2.png";
import BG_1 from "../assets/commons/BG_1.png";
import Collect from "../assets/commons/Collect.png";
import Profit from "../assets/commons/Profit.png";
import home_logo from "../assets/commons/home_logo.png";
import cwaty_logo from "../assets/commons/cwaty_logo.jpg";
import Tyeb_Mehta from "../assets/commons/Tyeb_Mehta.jpeg";
import S_H_Raza from "../assets/commons/S_H_Raza.jpeg";
import Francis_Newton_Souza from "../assets/commons/Francis_Newton_Souza.jpeg";
import m_f_husain from "../assets/commons/m_f_husain.jpg";

// blog
import blog1 from "../assets/commons/blog1.jpg";
import blog2 from "../assets/commons/blog2.jpeg";
import blog3 from "../assets/commons/blog3.jpeg";
import blog4 from "../assets/commons/blog4.jpeg";
import blog5 from "../assets/commons/blog5.jpeg";
import blog6 from "../assets/commons/blog6.webp";

// mobile

import Baner_1 from "../assets/Mobile/Baner_1.webp";
import Baner_2 from "../assets/Mobile/Baner_2.webp";
import Baner_3 from "../assets/Mobile/Baner_3.webp";
import Baner_4 from "../assets/Mobile/Baner_4.webp";
import Baner_5 from "../assets/Mobile/Baner_5.jpg";
import Baner_6 from "../assets/Mobile/Baner_6.webp";
import Baner_7 from "../assets/Mobile/Baner_7.webp";
import Aboutus_mobile from "../assets/Mobile/Aboutus_mobile.webp";
import How_it_Works_mobile from "../assets/Mobile/How_it_Works_mobile.webp";
import Theapp_mobile from "../assets/Mobile/Theapp_mobile.webp";
import Theapp2_mobile from "../assets/Mobile/Theapp2_mobile.webp";
import somubhai_1 from "../assets/commons/somubhai_1.png";

// web
import Banner1 from "../assets/Web/Banner1.webp";
import Banner2 from "../assets/Web/Banner2.webp";
import Banner3 from "../assets/Web/Banner3.webp";
import Banner4 from "../assets/Web/Banner4.webp";
import Banner5 from "../assets/Web/Banner_5.jpg";
import Banner6 from "../assets/Web/Banner6.webp";
import Banner7 from "../assets/Web/Banner7.webp";
import Aboutus from "../assets/Web/Aboutus.webp";
import the_app from "../assets/Web/the_app.webp";
import The_app2 from "../assets/Web/The_app2.jpg";
import how_it_works from "../assets/Web/how_it_works.webp";
import howitwork2_bg from "../assets/Web/howitwork2_bg.webp";
import red_bg from "../assets/Web/red_bg.png";
import red_bg2 from "../assets/Web/red_bg2.webp";
import moma from "../assets/Web/moma.png";
import Tanishq from "../assets/Web/Tanishq.png";
import times_of_India from "../assets/Web/times-of-India.png";
import Titan from "../assets/Web/Titan.png";
import nike from "../assets/Web/nike.png";
import somubhai from "../assets/Web/somubhai.jpg";
import captcha from "../assets/commons/captcha.png";
import NFBs100 from "../assets/commons/100-NFBs.png";

import b_1 from "../assets/Mobile/b_1.jpg";
import b_2 from "../assets/Mobile/b_2.jpg";
import b_3 from "../assets/Mobile/b_3.jpg";
import b_4 from "../assets/Mobile/b_4.jpg";
import b_5 from "../assets/Mobile/b_5.jpg";
import b_6 from "../assets/Mobile/b_6.jpg";
import b_7 from "../assets/Mobile/b_7.jpg";

import b_web_1 from "../assets/Web/b_web_1.jpg";
import b_web_2 from "../assets/Web/b_web_2.jpg";
import b_web_3 from "../assets/Web/b_web_3.jpg";
import b_web_4 from "../assets/Web/b_web_4.jpg";
import b_web_5 from "../assets/Web/b_web_5.jpg";
import b_web_6 from "../assets/Web/b_web_6.jpg";
import b_web_7 from "../assets/Web/b_web_7.jpg";
import mobile_new from "../assets/Mobile/mobile_new.png";
import web_logo_new from "../assets/Web/web_logo_new.png";
import team2 from "../assets/commons/team2.jpg";
import team3 from "../assets/commons/team3.jpg";

// ourteam
import jagrat from "../assets/commons/jagratdesai.webp";
import cwaty from "../assets/commons/cwaty.webp";
import Cwaty from "../assets/commons/Cwaty.png";
import gokul from "../assets/commons/gokulnair.webp";
import somu from "../assets/commons/somudesai.webp";
import manoj_bhai from "../assets/commons/manoj_bhai.jpg";
import KhiaWadhawan from "../assets/commons/KhiaWadhawan.webp";
import img_21 from "../assets/commons/img_21.webp";
import BG_logo from "../assets/commons/BG_logo.png";
import cwaty_new from "../assets/commons/cwaty_new.png";
import ravi from "../assets/commons/ravibhai.jpg";
import rarerez_white from "../assets/commons/rarerez_white.png";
import rarerez from "../assets/commons/rarerez.png";
import red_circule_back from "../assets/commons/red_circule_back.png";
import red_circule_back2 from "../assets/commons/red_circule_back2.png";
import new_bg_circule from "../assets/commons/new_bg_circule.png";
import logo_gray from "../assets/commons/logo_gray.png";
import up_bg_image from "../assets/Web/up_bg_image.png";
import up_bg_image2 from "../assets/Web/up_bg_image2.png";

export default {
  rarerez,
  rarerez_white,
  // ourteam
  ravi,
  cwaty_new,
  BG_logo,
  img_21,
  Cwaty,
  jagrat,
  KhiaWadhawan,
  cwaty,
  gokul,
  somu,
  somubhai_1,
  team2,
  team3,
  // blog
  blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  blog6,

  captcha,
  NFBs100,
  // logos,
  new_logo,
  logo_white,
  full_logo_white,
  list,
  instagram,
  red_cube,
  red_cube_gard,
  red_cube_with_hand,
  five_cubes,
  bonds,
  Relese_NFB,
  multi_red_cube,
  right_arrow,
  left_arrow,
  BG_1,
  BG_2,
  Collect,
  Profit,
  home_logo,
  cwaty_logo,
  Tyeb_Mehta,
  S_H_Raza,
  Francis_Newton_Souza,
  m_f_husain,

  // mobile
  Aboutus_mobile,
  How_it_Works_mobile,
  Theapp_mobile,
  Theapp2_mobile,
  Baner_1,
  Baner_2,
  Baner_3,
  Baner_4,
  Baner_5,
  Baner_6,
  Baner_7,
  b_1,
  b_2,
  b_3,
  b_4,
  b_5,
  b_6,
  b_7,
  b_web_1,
  b_web_2,
  b_web_3,
  b_web_4,
  b_web_5,
  b_web_6,
  b_web_7,
  mobile_new,
  web_logo_new,
  // web
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Banner5,
  Banner6,
  Banner7,
  the_app,
  The_app2,
  Aboutus,
  how_it_works,
  howitwork2_bg,
  red_bg,
  red_bg2,
  moma,
  Tanishq,
  times_of_India,
  Titan,
  nike,
  somubhai,
  manoj_bhai,

  red_circule_back,
  red_circule_back2,
  new_bg_circule,
  logo_gray,
  up_bg_image,
  up_bg_image2,
};
