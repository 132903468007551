import React, { useEffect, useState } from "react";
import "./HowItWorks2.css";
import { images } from "../../constants";
import Slider from "react-slick";
import { Slide } from "react-reveal";

const SliderItems = [
  {
    id: 1,
    img: images.five_cubes,
    name: "Showcase Your Brilliance",
    des: "Upload videos, images, and product details to create a profile for your unique creations.",
  },
  {
    id: 2,
    img: images.red_cube,
    name: " Authenticate and Protect",
    des: "Verify your identity and authenticate your creations on the blockchain for ultimate protection.",
  },
  {
    id: 3,
    img: images.red_cube_gard,
    name: "Release rarerezs",
    des: "Choose the release date and the number of rarerezs for each creation to entice collectors.",
  },
  {
    id: 4,
    img: images.NFBs100,
    name: "Discover Rare rarerezs",
    des: "Explore the marketplace for unique rarerezs from a diverse range of creators worldwide.",
  },
  {
    id: 5,
    img: images.red_cube_with_hand,
    name: "Place Bids",
    des: "Engage in thrilling bidding wars to secure ownership of your desired rarerezs.",
  },
  {
    id: 6,
    img: images.multi_red_cube,
    name: "Curate Your Collection",
    des: "Build a digital collection of treasured rarerezs, becoming a custodian of art and legacy.",
  },
  // {
  //   id: 7,
  //   img: images.Relese_NFB,
  //   name: "RELEASE <br /> NFBS",
  //   des: "This revolutionary royalty structure empowers creators to earn ongoing royalties from the appreciation and trading of their digital assets, creating a sustainable and rewarding relationship between creators and collectors. With the NFB Club platform, the process is seamless: creators authenticate themselves, protect their products on the blockchain, digitize them into NFBs, and release them into the marketplace. Collectors have the opportunity to acquire these unique NFBs, while creators benefit from an innovative royalty structure that adds value to their creations. Join us on NFB Club and be a part of this groundbreaking movement in the world of collectibles and digital assets.",
  // },
];

const HowItWorks2 = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [visibleSlides, setVisibleSlides] = useState(3); // Default number of visible slides

  const CustomPrevArrow = (props) => {
    const { onClick } = props;
    return (
      <button
        className={`custom-arrow custom-prev-arrow ${
          currentSlide == 0 ? "hide-arrow" : ""
        }`}
        onClick={onClick}
      >
        <img
          src={images.left_arrow}
          style={{
            width: "30px",
            height: "30px",
            objectFit: "contain",
            zIndex: 11,
          }}
          alt="Previous"
        />
      </button>
    );
  };

  const CustomNextArrow = (props) => {
    const { onClick, currentSlide, slideCount } = props;
    console.log(
      " currentSlide === SliderItems.length - visibleSlides",
      currentSlide === SliderItems.length - visibleSlides
    );
    return (
      <div
        className={`custom-arrow custom-next-arrow ${
          currentSlide === SliderItems.length - visibleSlides
            ? "hide-arrow"
            : ""
        }`}
        onClick={onClick}
      >
        <img
          src={images.right_arrow}
          style={{ width: "30px", height: "30px", objectFit: "contain" }}
          alt="Next"
        />
      </div>
    );
  };

  const updateVisibleSlides = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth >= 1024) {
      setVisibleSlides(3);
    } else if (windowWidth >= 920) {
      setVisibleSlides(2);
    } else if (windowWidth >= 620) {
      setVisibleSlides(2);
    } else {
      setVisibleSlides(1);
    }
    // Reset the current slide to 0 whenever the visible slides change
    setCurrentSlide(0);
  };

  // Add event listener to update visible slides on resize
  useEffect(() => {
    updateVisibleSlides();
    window.addEventListener("resize", updateVisibleSlides);
    return () => window.removeEventListener("resize", updateVisibleSlides);
  }, []);

  const handleSlideChange = (current) => {
    setCurrentSlide(current);
  };

  const settingss = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    // afterChange: (current) => setCurrentSlide(current),
    // afterChange: (current) => console.log("current", current),
    afterChange: handleSlideChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,

          // infinite: true,
          // dots: true,
        },
      },
      {
        breakpoint: 920,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    // responsive: [
    //   {
    //     breakpoint: 1024,
    //     settings: {
    //       slidesToShow: 2,
    //       slidesToScroll: 1,
    //       infinite: true,
    //       dots: true,
    //     },
    //   },
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //       initialSlide: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <div className="howitwork2_main_wrapp">
      <div className="howitwork2_img_wrapp"> </div>
      <div className="howitwork2_main_section">
        <div className="howitwork2_text_wrapp">
          {/* <Slide top> */}
          {/* <p className="howitwork2_text1">HOW IT WORKS</p> */}
          <p className="howitwork2_text2">
            Your Gateway to Artistry and Uniqueness
          </p>

          {/* <p className="howitwork2_text3">
              Ready to embark on a transformative journey? Download the nfb.club
              beta app and become a pioneer in the world of digital collecting.
              Get early access to exclusive features, explore a vast range of
              NFBs, and be at the forefront of this exciting revolution. Join us
              and experience the future of art and collectibles in the palm of
              your hand.
            </p> */}
          {/* </Slide> */}
        </div>

        {/* slider start */}
        <div className="howitwork2_slider_wrapp">
          <Slider {...settingss}>
            {/* single card */}
            {SliderItems.map((item, index) => (
              <div className="howitwork2_card_wrapp" key={item.id}>
                {index === 0 && (
                  <div className="main-border-creator">
                    <span>CREATOR</span>
                    <hr
                      className="hr_main_border1
"
                    ></hr>
                  </div>
                )}
                {index === 1 && <hr className="hr_main_border"></hr>}
                {index === 2 && <hr className="hr_main_border"></hr>}
                {index === 3 && (
                  <div className="main-border-creator">
                    <span>COLLECTOR</span>
                    <hr
                      className="hr_main_border2"
                    ></hr>
                  </div>
                )}
                {index === 4 && <hr className="hr_main_border"></hr>}
                {index === 5 && <hr className="hr_main_border"></hr>}
                {/* <Slide left> */}
                <img
                  src={item.img}
                  className="howitwork2_card_img"
                  alt=""
                  style={{
                    height: "110px",
                    width: "110px",
                    objectFit: "contain",
                  }}
                />

                <div className="howitwork2_heading_wrapp">
                  <h2>{item.id}.</h2>
                  <p className="item_new_nm" dangerouslySetInnerHTML={{ __html: item.name }} />
                </div>
                <p className="howitwork2_description">{item.des}</p>
                {/* </Slide> */}
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks2;
