import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./blogdetails.css";
import { images } from "../../constants";

const BlogDetails = () => {
  const location = useLocation();
  const item = location.state; // Get the passed data from the location

  // Check if 'item' exists before accessing its properties
  if (!item) {
    return <p>No data available.</p>;
  }

  // Now you can use the 'item' object to display the details of the blog post
  return (
    <div className="blogdetails_main_wrapp">
      <div className="blogdetails_main_wrapp_heading">
        <h1 className="h1_blog_details">
          <Link to="/blog">BLOG</Link> &gt; &nbsp; {item.heading}
        </h1>
        <br />
        <br />
        <div className="topic_nm_blog">
          <span className="details_topic_nm">TOPIC NAME</span>
        </div>
        <br />
        <br />
        <div className="published_date">
          <p>Published {item.date}</p>
        </div>
        <br />
        <br />
        <div className="blog_nm">
          <p className="blog_nm_data">This Week in Web3 and NFTs</p>
        </div>
        <div className="blog_written">
          <img src={images.logo_white} alt="" />
          <p className="written_nm">Written by rarerez.com Crew</p>
        </div>
        <img src={item.img} className="blog_details_img" alt="" />
        <p className="img_nm">{item.description}</p>
        <br />
        <h1 className="blog_detail_2">
          Jack Butcher's Custom ThreadGuy Opepen NFT Delights Web3 Community
        </h1>
        <br />
        <div className="blog_details_3">
          <p>
            In a heartwarming gesture that captured the attention of Crypto
            Twitter, multidisciplinary artist, and Checks founder Jack Butcher
            surprised prominent web3 commentator ThreadGuy with a custom Opepen
            image made using the color palette of Thread Guy's recognizable
            Mutant Ape PFP. This unexpected act of kindness sparked excitement
            and joy among NFT enthusiasts and creators alike.
          </p>
          <br />
          <p>
            Butcher sent ThreadGuy the personalized Opepen image on July 13
            after ThreadGuy posted a bootleg version of the artwork that he had
            made himself on Twitter.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
