import React, { useEffect, useRef, useState } from "react";
import "./homepage/HomePage.css";
import HomeSlider from "./homeslider/HomeSlider";
import AboutUs from "./aboutus/AboutUs";
import TheApp from "./theapp/TheApp";
import HowItWorks from "./howitworks/HowItWorks";
import TheApp2 from "./theapp2/TheApp2";
import MenuBar from "./menubar/MenuBar";
import HowItWorks2 from "./howitworks2/HowItWorks2";
import CreatorAndCollector from "./cereatoandrcollector/CreatorAndCollector";
import Testimonies from "./testimonies/Testimonies";
import Ourteamhome from "./ourteamhome/Ourteamhome";
import Contactus from "./contact/contactus";
import { HiMiniHome } from "react-icons/hi2";
import { useScroll } from "../ScrollContext";
import { useLocation, useNavigate } from "react-router-dom";
import DiscoverUniq from "./discoverUniq/DiscoverUniq";
import UnlockPower from "./unlockPower/UnlockPower";
import UnlockPower2 from "./unlockPower2/UnlockPower2";
import DiscoverUniq2 from "./discoverUniq2/DiscoverUniq2";
const useSectionRefs = (length) => {
  const sectionRefs = Array.from({ length }).map(() => React.createRef());
  return sectionRefs;
};
const Home = () => {
  const components = [
    <HomeSlider />,
    // <UnlockPower />,
    <UnlockPower2 />,
    // <DiscoverUniq />,
    <DiscoverUniq2 />,
    <CreatorAndCollector />,
    <AboutUs />,
    <TheApp />,
    <TheApp2 />,
    <HowItWorks2 />,
    <HowItWorks />,
    // <Contactus />,
    // <Ourteamhome />,
    <Testimonies />,
    // Add more divs for additional slides
  ];

  const [currentComponent, setCurrentComponent] = useState(0);
  const { scrollTo } = useScroll();
  const navigate = useNavigate();
  const location = useLocation();

  const sectionRefs = useSectionRefs(components.length);

  useEffect(() => {
    console.log("useScroll", scrollTo);

    setCurrentComponent(scrollTo);
    const sectionRef = sectionRefs[scrollTo].current;
    if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: "smooth" });
    }
  }, [scrollTo]);

  useEffect(() => {
    const handleScroll = () => {
      // Calculate the current component index based on the scroll position
      const componentIndex = Math.floor(window.scrollY / window.innerHeight);
      setCurrentComponent(componentIndex);
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleButtonClick = (index) => {
    setCurrentComponent(index);
    const sectionRef = sectionRefs[index].current;
    if (sectionRef) {
      sectionRef.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    // Check if the URL doesn't match any defined routes and redirect to home
    const validRoutes = [
      "/",
      "/Collector",
      "/Creator",
      "/blog",
      "/termsofuse",
      "/blogdetails",
      "/ourteamhome",
      "/contact",
    ];
    if (!validRoutes.includes(location.pathname)) {
      navigate("/");
    }
  }, [location, navigate]);

  useEffect(() => {
    // Intersection Observer callback to handle intersection changes
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = sectionRefs.findIndex(
            (ref) => ref.current === entry.target
          );
          setCurrentComponent(index);
        }
      });
    };

    // Create an Intersection Observer instance
    const observer = new IntersectionObserver(handleIntersect, {
      threshold: 0.5, // Adjust this threshold value based on your requirement
    });

    // Observe all sectionRefs
    sectionRefs.forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    // Clean up the observer when the component unmounts
    return () => {
      observer.disconnect();
    };
  }, [sectionRefs]);

  return (
    <div className="home_main_wrapp">
      {components.map((component, index) => (
        <div
          key={index}
          className={`section_home_slide ${
            currentComponent === index ? "active_home_slide" : ""
          }`}
          ref={sectionRefs[index]}
        >
          {component}
        </div>
      ))}

      {/* Pagination */}
      <div className="pagination_home">
        <div className="home-logo" onClick={() => handleButtonClick(0)}>
          <HiMiniHome
            color="#fff"
            size={18}
            style={{
              color: currentComponent === 0 ? "#fff" : "var(--color-main)",
            }}
          />
        </div>

        {components.map(
          (component, index) =>
            index !== 0 && (
              <button
                key={index}
                className={currentComponent === index ? "active" : ""}
                onClick={() => handleButtonClick(index)}
              />
            )
        )}
      </div>
    </div>
  );
};

export default Home;
